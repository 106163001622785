import loadConfig from "../../../../config";

export const Signin = async () => {
  const config = await loadConfig();
  // console.log(config.pythonBaseURL);
  const customerSignin = (data) => {
    return fetch(`${config.pythonBaseURL}/user/signin/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    }).then((res) => res.json());
  };
  return customerSignin;
};
