import React from 'react'

 const About = () => {
  return (
    <div>
      <h1>This is About page...</h1>
    </div>
  )
}
export default About;
