import React from 'react'

const Services = () => {
  return (                        
    <div>
       <h1>This is Service page...</h1>
    </div>
  )
}

export default Services;
