import { Menubar } from "primereact/menubar";
// import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/logo.svg";
import MenuComponent from "../menu/menu";

import "./navbar.css";
function NavBar() {
  // const dispatch = useDispatch();
  const items = [
    {
      label: "DOCPI",
    },
  ];

  const start = (
    <img
      alt="logo"
      src={logo}
      height="40"
      className="mr-2 "
      style={{ marginLeft: "15px" }}
    ></img>
  );
  return (
    <div className="card">
      <Menubar model={items} start={start} end={MenuComponent} />
    </div>
  );
}

export default NavBar;
