import React, { useState } from "react";
import RuleList from "../components/LandingPageComponent/RuleList";
import { useEffect } from "react";
import { createAxiosInstance as myAxios } from "../services/Helper";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [rules, setRules] = useState([]);
  const navigate = useNavigate();
  let getAllRules = async () => {
    // console.log("Executing started now ");
    try {
      const axiosInstance = await myAxios();
      let ruleList = await axiosInstance
        .get("/mtm/rulelist")
        .then((res) => res)
        .then((finalRes) => finalRes)
        .catch((e) => console.log(e));
    } catch (e) {
      if (e.response && e.response.status === 401) {
        navigate("/login");
      } else {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    getAllRules();
  }, []);

  return (
    <>
      <RuleList setRules={setRules} rules={rules} />
    </>
  );
};
export default Home;
