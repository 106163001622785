// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// // import "bootstrap/dist/css/bootstrap.min.css";
// import "react-toastify/dist/ReactToastify.css";
// import { BrowserRouter } from "react-router-dom";
// import { UserProvider } from "./components/contextAPIs/UserContext";
// import { TimeSchedulingProvider } from "./components/scheduleComponent/ContextTimeScheduling";
// import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
// import 'primeicons/primeicons.css';
// import './styles/styles.scss';
// import 'primeflex/primeflex.css';
// import 'primereact/resources/primereact.css';
// import "primereact/resources/themes/lara-light-cyan/theme.css";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <PrimeReactProvider>
//     <TimeSchedulingProvider>
//       <BrowserRouter>
//         <UserProvider>
//           <App />
//         </UserProvider>
//       </BrowserRouter>
//     </TimeSchedulingProvider>
//   </PrimeReactProvider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./components/contextAPIs/UserContext";
import { TimeSchedulingProvider } from "./components/scheduleComponent/ContextTimeScheduling";
import { PrimeReactProvider } from "primereact/api";
import { Provider } from "react-redux"; // Import the Provider
import store from "./DocumentReview/src/store";
import "primeicons/primeicons.css";
import "./styles/styles.scss";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {" "}
    {/* Wrap your app with Provider */}
    <PrimeReactProvider>
      <TimeSchedulingProvider>
        <BrowserRouter>
          <UserProvider>
            <App />
          </UserProvider>
        </BrowserRouter>
      </TimeSchedulingProvider>
    </PrimeReactProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
