// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maindiv{
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    margin-top: rem;
    max-height: 70vh;
    overflow-y: auto;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    /* border: 2px solid red; */
    margin: 1rem;
    margin-top: 1rem;
}
 td .edit_button:hover
{
    font-size: large;
    cursor: pointer;
    color: #ff4200;

}
.maindiv .listtable
{
    width: 100%;
    max-width: 66rem;
    margin: auto;
    box-sizing: border-box;
    margin-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/UsersComponent/UserList.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,4BAA4B;IAC5B,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,iCAAiC;IACjC,2BAA2B;IAC3B,YAAY;IACZ,gBAAgB;AACpB;CACC;;IAEG,gBAAgB;IAChB,eAAe;IACf,cAAc;;AAElB;AACA;;IAEI,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".maindiv{\r\n    width: 100%;\r\n    background-color: white;\r\n    display: flex;\r\n    flex-direction: column;\r\n    box-shadow: 0px 0px 8px #ddd;\r\n    border-radius: 10px;\r\n    margin-top: rem;\r\n    max-height: 70vh;\r\n    overflow-y: auto;\r\n}\r\n\r\n.table-responsive {\r\n    overflow-x: auto;\r\n    -webkit-overflow-scrolling: touch;\r\n    /* border: 2px solid red; */\r\n    margin: 1rem;\r\n    margin-top: 1rem;\r\n}\r\n td .edit_button:hover\r\n{\r\n    font-size: large;\r\n    cursor: pointer;\r\n    color: #ff4200;\r\n\r\n}\r\n.maindiv .listtable\r\n{\r\n    width: 100%;\r\n    max-width: 66rem;\r\n    margin: auto;\r\n    box-sizing: border-box;\r\n    margin-top: 2rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
