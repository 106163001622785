// import React, { useState, useRef, useEffect } from "react";
// import { Divider } from "primereact/divider";
// import { Card } from "primereact/card";
// import { Button } from "primereact/button";
// import { InputText } from "primereact/inputtext";
// import { Dialog } from "primereact/dialog";
// import { Toast } from "primereact/toast";
// import { createAxiosInstance as myAxios } from "../services/Helper";
// import { useNavigate } from "react-router-dom";
// import { Dropdown } from "primereact/dropdown";
// import "primeicons/primeicons.css";
// import "./VaultSecret.css";
// import { Password } from "primereact/password";

// export default function CreateSecret() {
//   const [sftpVisible, setSftpVisible] = useState(false);
//   const [dbVisible, setDbVisible] = useState(false);
//   const [sftpError, setSftpError] = useState({});
//   const [dbError, setDbError] = useState({});
//   const navigate = useNavigate();

//   //Only for Admin
//   const [isAdmin, setIsAdmin] = useState(false);

//   //env options
//   const env = [{ name: "DEV" }, { name: "QA" }, { name: "PROD" }];

//   useEffect(() => {
//     if (localStorage.getItem("userRole")?.includes("ADMIN")) {
//       setIsAdmin(true);
//     }
//   }, []);

//   useEffect(() => {
//     setSftpValue({
//       secretName: "",
//       company: localStorage.getItem("company") || "",
//       env: "",
//       host: "",
//       port: "",
//       username: "",
//       password: "",
//       type: "sftp",
//     });
//     setDbValue({
//       secretName: "",
//       company: localStorage.getItem("company") || "",
//       env: "",
//       dbUrl: "",
//       username: "",
//       password: "",
//       type: "db",
//     });
//   }, [sftpVisible, dbVisible]);

//   const [sftpValue, setSftpValue] = useState({
//     secretName: "",
//     company: localStorage.getItem("company") || "",
//     env: "",
//     host: "",
//     port: "",
//     username: "",
//     password: "",
//     type: "sftp",
//   });

//   const [dbValue, setDbValue] = useState({
//     secretName: "",
//     company: localStorage.getItem("company") || "",
//     env: "",
//     dbUrl: "",
//     // tableName: "",
//     username: "",
//     password: "",
//     type: "db",
//   });

//   const toast = useRef(null);

//   const handleSftpInputChange = (e) => {
//     const { name, value } = e.target;
//     setSftpValue({
//       ...sftpValue,
//       [name]: value,
//     });
//     setSftpError({}); // Clear error message on input change
//   };

//   const handleDbInputChange = (e) => {
//     const { name, value } = e.target;
//     setDbValue({
//       ...dbValue,
//       [name]: value,
//     });
//     setDbError({}); // Clear error message on input change
//   };

//   const showToast = (severity, summary, detail) => {
//     toast.current.show({ severity, summary, detail, life: 3000 });
//   };

//   const handleSFTPCreate = async (e) => {
//     e.preventDefault();

//     const errors = {};

//     // Check for empty fields and log them
//     if (!sftpValue.secretName) errors.secretName = true;

//     // if (!sftpValue.company) errors.company = true;
//     if (!sftpValue.env) errors.env = true;
//     if (!sftpValue.host) errors.host = true;
//     if (!sftpValue.port) errors.port = true;
//     if (!sftpValue.username) errors.username = true;
//     if (!sftpValue.password) errors.password = true;

//     if (Object.keys(errors).length > 0) {
//       console.log("I am in HandleSFTP secrets");
//       setSftpError(errors);
//       return;
//     }

//     const requestData = {
//       name: sftpValue.secretName,
//       type: sftpValue.type,
//       company: localStorage.getItem("company") || "",
//       env: sftpValue.env,
//       data: [
//         { name: "host", value: sftpValue.host },
//         { name: "port", value: sftpValue.port },
//         { name: "username", value: sftpValue.username },
//         { name: "password", value: sftpValue.password },
//       ],
//     };

//     // console.log("Request Data", requestData);

//     const axiosInstance = await myAxios();

//     try {
//       const response = await axiosInstance
//         .post("/secrets/create", requestData)
//         .then((res) => res.data)

//         .catch((e) => e.response);
//       console.log("Request Data", response);

//       if (response.status === 201) {
//         showToast("success", "Success", "Secrets created successfully!");
//       } else if (response.status === 401) {
//         navigate("/login");
//       }

//       // Reset state after success
//       setSftpVisible(false); // Close dialog
//       setSftpValue({
//         secretName: "",
//         company: localStorage.getItem("company") || "",
//         env: "",
//         host: "",
//         port: "",
//         username: "",
//         password: "",
//         type: "sftp",
//       });
//     } catch (error) {
//       console.log("error : ", error);
//       showToast("error", "Error", "Failed to create secrets: " + error.message);
//     }
//   };

//   const handleDBCreate = async (e) => {
//     e.preventDefault();

//     // Initialize error state
//     const errors = {};
//     // Check for empty fields
//     if (!dbValue.secretName) errors.secretName = true;
//     // if (!dbValue.company) errors.company = true;
//     if (!dbValue.env) errors.env = true;
//     if (!dbValue.dbUrl) errors.dbUrl = true;
//     if (!dbValue.username) errors.username = true;
//     if (!dbValue.password) errors.password = true;

//     // Set errors and return if there are any
//     if (Object.keys(errors).length > 0) {
//       console.log(errors);
//       setDbError(errors);
//       return;
//     }

//     const requestData = {
//       name: dbValue.secretName,
//       type: dbValue.type,
//       company: localStorage.getItem("company") || "",
//       env: dbValue.env,
//       data: [
//         { name: "dburl", value: dbValue.dbUrl },
//         { name: "username", value: dbValue.username },
//         { name: "password", value: dbValue.password },
//       ],
//     };

//     const axiosInstance = await myAxios();

//     try {
//       const response = await axiosInstance
//         .post("/secrets/create", requestData)
//         .then((res) => res.data)
//         .catch((e) => e.response);

//       if (response.status === 201) {
//         showToast("success", "Success", "Secrets created successfully!");
//       } else if (response.status === 401) {
//         navigate("/login");
//       }
//       setDbVisible(false); // Close the dialog on success
//       // Reset form values after successful submission
//       setDbValue({
//         secretName: "",
//         company: localStorage.getItem("company") || "",
//         env: "",
//         dbUrl: "",
//         username: "",
//         password: "",
//         type: "db",
//       });
//     } catch (error) {
//       console.log(error.message);
//       showToast("error", "Error", "Failed to create secrets: " + error.message);
//     }
//     console.log(dbValue);
//   };

//   const sftpFooterContent = (
//     <div>
//       <Button
//         label="No"
//         icon="pi pi-times"
//         onClick={() => setSftpVisible(false)}
//         className="p-button-text"
//       />
//       <Button
//         label="Submit"
//         icon="pi pi-check"
//         onClick={handleSFTPCreate}
//         autoFocus
//       />
//     </div>
//   );

//   const dbFooterContent = (
//     <div>
//       <Button
//         label="No"
//         icon="pi pi-times"
//         onClick={() => setDbVisible(false)}
//         className="p-button-text"
//       />
//       <Button
//         label="Submit"
//         icon="pi pi-check"
//         onClick={handleDBCreate}
//         autoFocus
//       />
//     </div>
//   );

//   const renderMandatoryFieldMessage = (value, error) => {
//     if (error) {
//       return <span style={{ color: "red" }}>This field is required</span>;
//     }
//     return null;
//   };

//   return (
//     <>
//       {!isAdmin && (
//         <div style={{ display: "flex", justifyContent: "center" }}>
//           <h1>You don't have access to this page please reach to Admin.</h1>
//         </div>
//       )}
//       {isAdmin && (
//         <div>
//           <Toast ref={toast} />
//           <div className="card" style={{ width: "100%" }}>
//             <h1 className="Header">Vault Setup </h1>
//             <Divider />
//           </div>
//           <div className="card1">
//             <Card title="Create Secret">
//               <div style={{ marginTop: "-20px" }}>
//                 <p1>Select the type of secret you want to create</p1>
//               </div>

//               <div className="card flex flex-wrap justify-content-start">
//                 <div
//                   className="SFTPSecrets hover-effect"
//                   style={{
//                     display: "flex", // Align items in a row (horizontal)
//                     alignItems: "center", // Vertically center the items
//                     padding: "20px", // Add padding to give some space around the content
//                     borderRadius: "8px", // Optional: rounded corners for aesthetics
//                     margin: "10px", // Optional: margin around the div
//                     width: "100%",
//                   }}
//                   onClick={() => setSftpVisible(true)}
//                 >
//                   <div>
//                     <i
//                       className="pi pi-server"
//                       style={{ fontSize: "30px" }}
//                     ></i>
//                   </div>
//                   <div style={{ marginLeft: "20px", marginTop: "-20px" }}>
//                     <h4>SFTP Secrets</h4>
//                     <div style={{ marginTop: "-15px" }}>
//                       Securely store and manage SFTP Credentials
//                     </div>
//                   </div>
//                   <div style={{ marginLeft: "auto" }}>
//                     <i className="pi pi-angle-right"></i>
//                   </div>
//                 </div>
//                 <div
//                   className="DBSecrets hover-effect"
//                   style={{
//                     display: "flex", // Align items in a row (horizontal)
//                     alignItems: "center", // Vertically center the items
//                     padding: "20px", // Add padding to give some space around the content
//                     borderRadius: "8px", // Optional: rounded corners for aesthetics
//                     margin: "10px", // Optional: margin around the div
//                     width: "100%",
//                   }}
//                   onClick={() => setDbVisible(true)}
//                 >
//                   <div>
//                     <i
//                       className="pi pi-database"
//                       style={{ fontSize: "30px" }}
//                     ></i>
//                   </div>
//                   <div style={{ marginLeft: "20px", marginTop: "-20px" }}>
//                     <h4>Database Secrets</h4>
//                     <div style={{ marginTop: "-15px" }}>
//                       Securely store and manage Database Credentials
//                     </div>
//                   </div>
//                   <div style={{ marginLeft: "auto" }}>
//                     <i className="pi pi-angle-right"></i>
//                   </div>
//                 </div>
//               </div>
//             </Card>
//           </div>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center", // Center horizontally
//               alignItems: "center", // Center vertically
//             }}
//           >
//             <Card
//               style={{
//                 marginTop: "30px",
//                 background: " rgba(88, 219, 255, 0.2)",
//                 width: "70%",
//               }}
//             >
//               <div style={{ display: "flex", alignItems: "center" }}>
//                 <i
//                   className="pi pi-lock"
//                   style={{
//                     fontSize: "20px",
//                     marginRight: "10px",
//                   }}
//                 ></i>
//                 <h4 style={{ margin: 0 }}>Secure Storage</h4>
//               </div>
//               <div
//                 style={{
//                   marginTop: "10px",
//                   marginLeft: "30px",
//                 }}
//               >
//                 <p1>
//                   All secrets are encrypted using industry-standard algorithms
//                   and stored Securely. Only authorized users with appropriate
//                   permissions can access these secrets
//                 </p1>
//               </div>
//             </Card>
//           </div>

//           {/* SFTP Secrets Dialog */}
//           <Dialog
//             header="SFTP Secret Details"
//             visible={sftpVisible}
//             style={{ width: "30vw" }} // Adjust the width as needed
//             onHide={() => setSftpVisible(false)}
//             footer={sftpFooterContent}
//           >
//             <div className="flex flex-column align-items-start">
//               <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="sftpName">Name of Secrets</label>
//                 <div>
//                   {renderMandatoryFieldMessage(
//                     sftpValue.secretName,
//                     sftpError.secretName
//                   )}
//                 </div>
//                 <InputText
//                   id="sftpName"
//                   name="secretName"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                 />
//               </div>

//               {/* <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="host">Company Name</label>
//                 <div>
//                   {renderMandatoryFieldMessage(
//                     sftpValue.company,
//                     sftpError.company
//                   )}
//                 </div>
//                 <InputText
//                   id="company"
//                   name="company"
//                   value={sftpValue.company}
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                 />
//               </div> */}

//               <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="host">Environment</label>
//                 <div>
//                   {renderMandatoryFieldMessage(sftpValue.env, sftpError.env)}
//                 </div>
//                 <Dropdown
//                   id="env"
//                   name="env"
//                   value={env.find((e) => e.name === sftpValue.env)}
//                   onChange={(e) => {
//                     setSftpValue({
//                       ...sftpValue,
//                       env: e.value.name,
//                     });
//                   }}
//                   options={env}
//                   optionLabel="name"
//                   placeholder="Select an Environment"
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                 />
//               </div>

//               <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="host">SFTP Host</label>
//                 <div>
//                   {renderMandatoryFieldMessage(sftpValue.host, sftpError.host)}
//                 </div>
//                 <InputText
//                   id="host"
//                   name="host"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                 />
//               </div>
//               <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="sftpPort">Port</label>
//                 <div>
//                   {renderMandatoryFieldMessage(sftpValue.port, sftpError.port)}
//                 </div>
//                 <InputText
//                   id="sftpPort"
//                   name="port"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                 />
//               </div>
//               <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="sftpUserName">Username</label>
//                 <div>
//                   {renderMandatoryFieldMessage(
//                     sftpValue.username,
//                     sftpError.username
//                   )}
//                 </div>
//                 <InputText
//                   id="sftpUserName"
//                   name="username"
//                   onChange={handleSftpInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                 />
//               </div>
//               <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="sftpPassword">Password</label>
//                 <div>
//                   {renderMandatoryFieldMessage(
//                     sftpValue.password,
//                     sftpError.password
//                   )}
//                 </div>
//                 <Password
//                   className="w-full"
//                   id="sftpPassword"
//                   name="password"
//                   type="password"
//                   toggleMask
//                   onChange={handleSftpInputChange}
//                   required
//                 />
//               </div>
//             </div>
//           </Dialog>

//           {/* Database Secrets Dialog */}
//           <Dialog
//             header="Database Secret Details"
//             visible={dbVisible}
//             style={{ width: "30vw" }} // Adjust the width as needed
//             onHide={() => setDbVisible(false)}
//             footer={dbFooterContent}
//           >
//             <div className="flex flex-column align-items-start">
//               <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="dbName">Secret Name</label>
//                 <div>
//                   {renderMandatoryFieldMessage(
//                     dbValue.secretName,
//                     dbError.secretName
//                   )}
//                 </div>
//                 <InputText
//                   id="dbName"
//                   name="secretName"
//                   onChange={handleDbInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                 />
//               </div>

//               {/* <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="host">Company Name</label>
//                 <div>
//                   {renderMandatoryFieldMessage(
//                     dbValue.company,
//                     dbError.company
//                   )}
//                 </div>
//                 <InputText
//                   id="company"
//                   name="company"
//                   value={dbValue.company}
//                   onChange={handleDbInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                 />
//               </div> */}

//               <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="host">Environment</label>
//                 <div>
//                   {renderMandatoryFieldMessage(sftpValue.env, sftpError.env)}
//                 </div>
//                 <Dropdown
//                   id="env"
//                   name="env"
//                   value={env.find((e) => e.name === dbValue.env)}
//                   onChange={(e) =>
//                     setDbValue({
//                       ...dbValue,
//                       env: e.value.name,
//                     })
//                   }
//                   options={env}
//                   optionLabel="name"
//                   placeholder="Select a Environment"
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                 />
//               </div>
//               <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="dbUrl">Database URL</label>
//                 <div>
//                   {renderMandatoryFieldMessage(dbValue.dbUrl, dbError.dbUrl)}
//                 </div>
//                 <InputText
//                   id="dbUrl"
//                   name="dbUrl"
//                   onChange={handleDbInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                 />
//               </div>

//               <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="dbUserName">Username</label>
//                 <div>
//                   {renderMandatoryFieldMessage(
//                     dbValue.username,
//                     dbError.username
//                   )}
//                 </div>
//                 <InputText
//                   id="dbUserName"
//                   name="username"
//                   onChange={handleDbInputChange}
//                   required
//                   style={{ width: "100%", fontSize: "1.2em" }}
//                 />
//               </div>
//               <div
//                 className="p-field"
//                 style={{ width: "100%", marginBottom: "1rem" }}
//               >
//                 <label htmlFor="dbPassword">Password</label>
//                 <div>
//                   {renderMandatoryFieldMessage(
//                     dbValue.password,
//                     dbError.password
//                   )}
//                 </div>
//                 <Password
//                   className="w-full"
//                   id="dbPassword"
//                   name="password"
//                   type="password"
//                   toggleMask
//                   onChange={handleDbInputChange}
//                   required
//                 />
//               </div>
//             </div>
//           </Dialog>
//         </div>
//       )}
//     </>
//   );
// }

import React, { useState, useRef, useEffect } from "react";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { createAxiosInstance as myAxios } from "../services/Helper";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import "primeicons/primeicons.css";
import "./VaultSecret.css";
import { Password } from "primereact/password";

export default function CreateSecret() {
  const [sftpVisible, setSftpVisible] = useState(false);
  const [dbVisible, setDbVisible] = useState(false);
  const [sftpError, setSftpError] = useState({});
  const [dbError, setDbError] = useState({});
  const navigate = useNavigate();

  //Only for Admin
  const [isAdmin, setIsAdmin] = useState(false);

  //env options
  const env = [{ name: "DEV" }, { name: "QA" }, { name: "PROD" }];

  useEffect(() => {
    if (localStorage.getItem("userRole")?.includes("ADMIN")) {
      setIsAdmin(true);
    }
  }, []);

  useEffect(() => {
    setSftpValue({
      secretName: "",
      company: localStorage.getItem("company") || "",
      host_dev: "",
      port_dev: "",
      username_dev: "",
      password_dev: "",
      host_qa: "",
      port_qa: "",
      username_qa: "",
      password_qa: "",
      host_prod: "",
      port_prod: "",
      username_prod: "",
      password_prod: "",
      type: "sftp",
    });
    setDbValue({
      secretName: "",
      company: localStorage.getItem("company") || "",
      type: "db",
      dbUrl_dev: "",
      username_dev: "",
      password_dev: "",
      dbUrl_qa: "",
      username_qa: "",
      password_qa: "",
      dbUrl_prod: "",
      username_prod: "",
      password_prod: "",
    });
  }, [sftpVisible, dbVisible]);

  const [sftpValue, setSftpValue] = useState({
    secretName: "",
    company: localStorage.getItem("company") || "",
    host_dev: "",
    port_dev: "",
    username_dev: "",
    password_dev: "",
    host_qa: "",
    port_qa: "",
    username_qa: "",
    password_qa: "",
    host_prod: "",
    port_prod: "",
    username_prod: "",
    password_prod: "",
    type: "sftp",
  });

  const [dbValue, setDbValue] = useState({
    secretName: "",
    company: localStorage.getItem("company") || "",
    type: "db",
    dbUrl_dev: "",
    username_dev: "",
    password_dev: "",
    dbUrl_qa: "",
    username_qa: "",
    password_qa: "",
    dbUrl_prod: "",
    username_prod: "",
    password_prod: "",
  });

  const toast = useRef(null);

  const handleSftpInputChange = (e) => {
    const { name, value } = e.target;
    setSftpValue({
      ...sftpValue,
      [name]: value,
    });
    setSftpError({}); // Clear error message on input change
  };

  const handleDbInputChange = (e) => {
    const { name, value } = e.target;
    setDbValue({
      ...dbValue,
      [name]: value,
    });
    setDbError({}); // Clear error message on input change
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail, life: 3000 });
  };

  // const handleSFTPCreate = () => {
  //   console.log(sftpValue);
  // };
  const handleSFTPCreate = async (e) => {
    e.preventDefault();

    const errors = {};

    // Check for empty fields and log them
    if (!sftpValue.secretName) errors.secretName = true;

    // if (!sftpValue.company) errors.company = true;
    // if (!sftpValue.env) errors.env = true;
    if (!sftpValue.host_dev) errors.host_dev = true;
    if (!sftpValue.port_dev) errors.port_dev = true;
    if (!sftpValue.username_dev) errors.username_dev = true;
    if (!sftpValue.password_dev) errors.password_dev = true;
    if (!sftpValue.host_qa) errors.host_qa = true;
    if (!sftpValue.port_qa) errors.port_qa = true;
    if (!sftpValue.username_qa) errors.username_qa = true;
    if (!sftpValue.password_qa) errors.password_qa = true;
    if (!sftpValue.host_prod) errors.host_prod = true;
    if (!sftpValue.port_prod) errors.port_prod = true;
    if (!sftpValue.username_prod) errors.username_prod = true;
    if (!sftpValue.password_prod) errors.password_prod = true;

    if (Object.keys(errors).length > 0) {
      // console.log("I am in HandleSFTP secrets");
      setSftpError(errors);
      return;
    }

    const requestData = {
      name: sftpValue.secretName,
      type: sftpValue.type,
      company: localStorage.getItem("company") || "",
      dev: {
        host: sftpValue.host_dev,
        port: sftpValue.port_dev,
        username: sftpValue.username_dev,
        password: sftpValue.password_dev,
      },
      qa: {
        host: sftpValue.host_qa,
        port: sftpValue.port_qa,
        username: sftpValue.username_qa,
        password: sftpValue.password_qa,
      },
      prod: {
        host: sftpValue.host_prod,
        port: sftpValue.port_prod,
        username: sftpValue.username_prod,
        password: sftpValue.password_prod,
      },
    };

    // console.log("Request Data", requestData);

    const axiosInstance = await myAxios();

    try {
      const response = await axiosInstance
        .post("/secrets/create", requestData)
        .then((res) => res.data)

        .catch((e) => e.response);
      // console.log("Request Data", response);

      if (response.status === 201) {
        showToast("success", "Success", "Secrets created successfully!");
      } else if (response.status === 401) {
        navigate("/login");
      }

      // Reset state after success
      setSftpVisible(false); // Close dialog
      setSftpValue({
        secretName: "",
        company: localStorage.getItem("company") || "",
        host_dev: "",
        port_dev: "",
        username_dev: "",
        password_dev: "",
        host_qa: "",
        port_qa: "",
        username_qa: "",
        password_qa: "",
        host_prod: "",
        port_prod: "",
        username_prod: "",
        password_prod: "",
        type: "sftp",
      });
    } catch (error) {
      console.log("error : ", error);
      showToast("error", "Error", "Failed to create secrets: " + error.message);
    }
  };

  const handleDBCreate = async (e) => {
    e.preventDefault();

    // Initialize error state
    const errors = {};
    // Check for empty fields
    if (!dbValue.secretName) errors.secretName = true;
    // if (!dbValue.company) errors.company = true;
    // if (!dbValue.env) errors.env = true;
    if (!dbValue.dbUrl_dev) errors.dbUrl_dev = true;
    if (!dbValue.username_dev) errors.username_dev = true;
    if (!dbValue.password_dev) errors.password_dev = true;
    if (!dbValue.dbUrl_qa) errors.dbUrl_qa = true;
    if (!dbValue.username_qa) errors.username_qa = true;
    if (!dbValue.password_qa) errors.password_qa = true;
    if (!dbValue.dbUrl_prod) errors.dbUrl_prod = true;
    if (!dbValue.username_prod) errors.username_prod = true;
    if (!dbValue.password_prod) errors.password_prod = true;

    // Set errors and return if there are any
    if (Object.keys(errors).length > 0) {
      console.log(errors);
      setDbError(errors);
      return;
    }

    const requestData = {
      name: dbValue.secretName,
      type: dbValue.type,
      company: localStorage.getItem("company") || "",
      dev: {
        dburl: dbValue.dbUrl_dev,
        username: dbValue.username_dev,
        password: dbValue.password_dev,
      },
      qa: {
        dburl: dbValue.dbUrl_qa,
        username: dbValue.username_qa,
        password: dbValue.password_qa,
      },
      prod: {
        dburl: dbValue.dbUrl_prod,
        username: dbValue.username_prod,
        password: dbValue.password_prod,
      },
    };

    const axiosInstance = await myAxios();

    try {
      const response = await axiosInstance
        .post("/secrets/create", requestData)
        .then((res) => res.data)
        .catch((e) => e.response);

      if (response.status === 201) {
        showToast("success", "Success", "Secrets created successfully!");
      } else if (response.status === 401) {
        navigate("/login");
      }
      setDbVisible(false); // Close the dialog on success
      // Reset form values after successful submission
      setDbValue({
        secretName: "",
        company: localStorage.getItem("company") || "",
        type: "db",
        dbUrl_dev: "",
        username_dev: "",
        password_dev: "",
        dbUrl_qa: "",
        username_qa: "",
        password_qa: "",
        dbUrl_prod: "",
        username_prod: "",
        password_prod: "",
      });
    } catch (error) {
      console.log(error.message);
      showToast("error", "Error", "Failed to create secrets: " + error.message);
    }
    // console.log(dbValue);
  };

  const sftpFooterContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setSftpVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={handleSFTPCreate}
        autoFocus
      />
    </div>
  );

  const dbFooterContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setDbVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={handleDBCreate}
        autoFocus
      />
    </div>
  );

  const renderMandatoryFieldMessage = (value, error) => {
    if (error) {
      return <span style={{ color: "red" }}>This field is required</span>;
    }
    return null;
  };

  return (
    <>
      {!isAdmin && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1>You don't have access to this page please reach to Admin.</h1>
        </div>
      )}
      {isAdmin && (
        <div>
          <Toast ref={toast} />
          <div className="card" style={{ width: "100%" }}>
            <h1 className="Header">Vault Setup </h1>
            <Divider />
          </div>
          <div className="card1">
            <Card title="Create Secret">
              <div style={{ marginTop: "-20px" }}>
                <p1>Select the type of secret you want to create</p1>
              </div>

              <div className="card flex flex-wrap justify-content-start">
                <div
                  className="SFTPSecrets hover-effect"
                  style={{
                    display: "flex", // Align items in a row (horizontal)
                    alignItems: "center", // Vertically center the items
                    padding: "20px", // Add padding to give some space around the content
                    borderRadius: "8px", // Optional: rounded corners for aesthetics
                    margin: "10px", // Optional: margin around the div
                    width: "100%",
                  }}
                  onClick={() => setSftpVisible(true)}
                >
                  <div>
                    <i
                      className="pi pi-server"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "-20px" }}>
                    <h4>SFTP Secrets</h4>
                    <div style={{ marginTop: "-15px" }}>
                      Securely store and manage SFTP Credentials
                    </div>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <i className="pi pi-angle-right"></i>
                  </div>
                </div>
                <div
                  className="DBSecrets hover-effect"
                  style={{
                    display: "flex", // Align items in a row (horizontal)
                    alignItems: "center", // Vertically center the items
                    padding: "20px", // Add padding to give some space around the content
                    borderRadius: "8px", // Optional: rounded corners for aesthetics
                    margin: "10px", // Optional: margin around the div
                    width: "100%",
                  }}
                  onClick={() => setDbVisible(true)}
                >
                  <div>
                    <i
                      className="pi pi-database"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div style={{ marginLeft: "20px", marginTop: "-20px" }}>
                    <h4>Database Secrets</h4>
                    <div style={{ marginTop: "-15px" }}>
                      Securely store and manage Database Credentials
                    </div>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <i className="pi pi-angle-right"></i>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
            }}
          >
            <Card
              style={{
                marginTop: "30px",
                background: " rgba(88, 219, 255, 0.2)",
                width: "70%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <i
                  className="pi pi-lock"
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                  }}
                ></i>
                <h4 style={{ margin: 0 }}>Secure Storage</h4>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "30px",
                }}
              >
                <p1>
                  All secrets are encrypted using industry-standard algorithms
                  and stored Securely. Only authorized users with appropriate
                  permissions can access these secrets
                </p1>
              </div>
            </Card>
          </div>

          {/* SFTP Secrets Dialog */}
          <Dialog
            header="SFTP Secret Details"
            visible={sftpVisible}
            style={{ width: "60vw" }} // Adjust the width as needed
            onHide={() => setSftpVisible(false)}
            footer={sftpFooterContent}
          >
            <div className="flex flex-column align-items-start col-12">
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="sftpName">Name of Secrets</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.secretName,
                    sftpError.secretName
                  )}
                </div>
                <InputText
                  id="sftpName"
                  name="secretName"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>

              <div
                className="p-field col-12"
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "space-between",
                }}
              >
                {/* Column 1 for PROD */}
                <div style={{ flex: "1" }} className="col-4">
                  <label htmlFor="env">Environment</label>

                  <InputText
                    id="env"
                    name="env"
                    value={"DEV"}
                    style={{ width: "100%", fontSize: "1.2em" }}
                    disabled
                  />
                  <label htmlFor="host_dev">SFTP Host</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.host_dev,
                      sftpError.host_dev
                    )}
                  </div>
                  <InputText
                    id="host_dev"
                    name="host_dev"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="sftpPort_dev">Port</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.port_dev,
                      sftpError.port_dev
                    )}
                  </div>
                  <InputText
                    id="sftpPort_dev"
                    name="port_dev"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="sftpUserName_dev">Username</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.username_dev,
                      sftpError.username_dev
                    )}
                  </div>
                  <InputText
                    id="sftpUserName_dev"
                    name="username_dev"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="sftpPassword_dev">Password</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.password_dev,
                      sftpError.password_dev
                    )}
                  </div>
                  <Password
                    className="w-full"
                    id="sftpPassword_dev"
                    name="password_dev"
                    type="password"
                    toggleMask
                    onChange={handleSftpInputChange}
                    required
                  />
                </div>

                {/* Column 2 for DEV */}
                <div style={{ flex: "1" }} className="col-4">
                  <label htmlFor="env">Environment</label>
                  <div>
                    {renderMandatoryFieldMessage(sftpValue.env, sftpError.env)}
                  </div>
                  <InputText
                    id="env"
                    name="env"
                    value={"QA"}
                    style={{ width: "100%", fontSize: "1.2em" }}
                    disabled
                  />
                  <label htmlFor="host_qa">SFTP Host</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.host_qa,
                      sftpError.host_qa
                    )}
                  </div>
                  <InputText
                    id="host_qa"
                    name="host_qa"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="sftpPort_qa">Port</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.port_qa,
                      sftpError.port_qa
                    )}
                  </div>
                  <InputText
                    id="sftpPort_qa"
                    name="port_qa"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="sftpUserName_qa">Username</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.username_qa,
                      sftpError.username_qa
                    )}
                  </div>
                  <InputText
                    id="sftpUserName_qa"
                    name="username_qa"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="sftpPassword_qa">Password</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.password_qa,
                      sftpError.password_qa
                    )}
                  </div>
                  <Password
                    className="w-full"
                    id="sftpPassword_qa"
                    name="password_qa"
                    type="password"
                    toggleMask
                    onChange={handleSftpInputChange}
                    required
                  />
                </div>

                {/* Column 3 for QA */}
                <div style={{ flex: "1" }} className="col-4">
                  <label htmlFor="env">Environment</label>
                  <div>
                    {renderMandatoryFieldMessage(sftpValue.env, sftpError.env)}
                  </div>
                  <InputText
                    id="env"
                    name="env"
                    value={"PROD"}
                    style={{ width: "100%", fontSize: "1.2em" }}
                    disabled
                  />
                  <label htmlFor="host_prod">SFTP Host</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.host_prod,
                      sftpError.host_prod
                    )}
                  </div>
                  <InputText
                    id="host_prod"
                    name="host_prod"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="sftpPort_prod">Port</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.port_prod,
                      sftpError.port_prod
                    )}
                  </div>
                  <InputText
                    id="sftpPort_prod"
                    name="port_prod"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="sftpUserName_prod">Username</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.username_prod,
                      sftpError.username_prod
                    )}
                  </div>
                  <InputText
                    id="sftpUserName_prod"
                    name="username_prod"
                    onChange={handleSftpInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="sftpPassword_prod">Password</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      sftpValue.password_prod,
                      sftpError.password_prod
                    )}
                  </div>
                  <Password
                    className="w-full"
                    id="sftpPassword_prod"
                    name="password_prod"
                    type="password"
                    toggleMask
                    onChange={handleSftpInputChange}
                    required
                  />
                </div>
              </div>
            </div>
          </Dialog>

          {/* Database Secrets Dialog */}
          <Dialog
            header="Database Secret Details"
            visible={dbVisible}
            style={{ width: "60vw" }} // Adjust the width as needed
            onHide={() => setDbVisible(false)}
            footer={dbFooterContent}
          >
            {/* <div className="flex flex-column align-items-start"> */}
            <div className="flex flex-column align-items-start col-12">
              <div
                className="p-field"
                style={{ width: "100%", marginBottom: "1rem" }}
              >
                <label htmlFor="dbName">Secret Name</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.secretName,
                    dbError.secretName
                  )}
                </div>
                <InputText
                  id="dbName"
                  name="secretName"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                />
              </div>

              <div
                className="p-field col-12"
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "space-between",
                }}
              >
                {/* Column 1 for PROD */}
                <div style={{ flex: "1" }} className="col-4">
                  <label htmlFor="host">Environment</label>
                  <InputText
                    id="env"
                    name="env"
                    value={"DEV"}
                    style={{ width: "100%", fontSize: "1.2em" }}
                    disabled
                  />
                  <label htmlFor="dbUrl_dev">Database URL</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      dbValue.dbUrl_dev,
                      dbError.dbUrl_dev
                    )}
                  </div>
                  <InputText
                    id="dbUrl_dev"
                    name="dbUrl_dev"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="dbUserName_dev">Username</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      dbValue.username_dev,
                      dbError.username_dev
                    )}
                  </div>
                  <InputText
                    id="dbUserName_dev"
                    name="username_dev"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="dbPassword_dev">Password</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      dbValue.password_dev,
                      dbError.password_dev
                    )}
                  </div>
                  <Password
                    className="w-full"
                    id="dbPassword_dev"
                    name="password_dev"
                    type="password"
                    toggleMask
                    onChange={handleDbInputChange}
                    required
                  />
                </div>
                <div style={{ flex: "1" }} className="col-4">
                  <label htmlFor="host">Environment</label>
                  <InputText
                    id="env"
                    name="env"
                    value={"QA"}
                    style={{ width: "100%", fontSize: "1.2em" }}
                    disabled
                  />
                  <label htmlFor="dbUrl_qa">Database URL</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      dbValue.dbUrl_qa,
                      dbError.dbUrl_qa
                    )}
                  </div>
                  <InputText
                    id="dbUrl_qa"
                    name="dbUrl_qa"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="dbUserName_qa">Username</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      dbValue.username_qa,
                      dbError.username_qa
                    )}
                  </div>
                  <InputText
                    id="dbUserName_qa"
                    name="username_qa"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="dbPassword_qa">Password</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      dbValue.password_qa,
                      dbError.password_qa
                    )}
                  </div>
                  <Password
                    className="w-full"
                    id="dbPassword_qa"
                    name="password_qa"
                    type="password_qa"
                    toggleMask
                    onChange={handleDbInputChange}
                    required
                  />
                </div>
                <div style={{ flex: "1" }} className="col-4">
                  <label htmlFor="host">Environment</label>
                  <InputText
                    id="env"
                    name="env"
                    value={"PROD"}
                    style={{ width: "100%", fontSize: "1.2em" }}
                    disabled
                  />
                  <label htmlFor="dbUrl_prod">Database URL</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      dbValue.dbUrl_prod,
                      dbError.dbUrl_prod
                    )}
                  </div>
                  <InputText
                    id="dbUrl_prod"
                    name="dbUrl_prod"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="dbUserName_prod">Username</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      dbValue.username_prod,
                      dbError.username_prod
                    )}
                  </div>
                  <InputText
                    id="dbUserName_prod"
                    name="username_prod"
                    onChange={handleDbInputChange}
                    required
                    style={{ width: "100%", fontSize: "1.2em" }}
                  />
                  <label htmlFor="dbPassword_prod">Password</label>
                  <div>
                    {renderMandatoryFieldMessage(
                      dbValue.password_prod,
                      dbError.password_prod
                    )}
                  </div>
                  <Password
                    className="w-full"
                    id="dbPassword_prod"
                    name="password_prod"
                    type="password_prod"
                    toggleMask
                    onChange={handleDbInputChange}
                    required
                  />
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
}
