// import React, { useState, useEffect, useRef } from "react";
// import { FilterMatchMode } from "primereact/api";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { IconField } from "primereact/iconfield";
// import { InputIcon } from "primereact/inputicon";
// import { Dropdown } from "primereact/dropdown";
// import { Button } from "primereact/button"; // For action buttons (Edit/Delete)
// import Breadcrumbs from "../components/Breadcrumb";
// import { createAxiosInstance as myAxios } from "../services/Helper";
// import { Dialog } from "primereact/dialog";
// import { Toast } from "primereact/toast";
// import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
// import { Password } from "primereact/password";
// import { useUserContext } from "../components/contextAPIs/UserContext";

// export default function ViewSecret() {
//   const [secrets, setSecrets] = useState([]);
//   const [filters, setFilters] = useState({
//     global: { value: null, matchMode: FilterMatchMode.CONTAINS },
//     name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
//     host: { value: null, matchMode: FilterMatchMode.CONTAINS },
//     port: { value: null, matchMode: FilterMatchMode.EQUALS },
//     username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
//   });
//   const [loading, setLoading] = useState(true);
//   const [globalFilterValue, setGlobalFilterValue] = useState("");
//   const [sftpList, setSftpList] = useState([]);
//   const [dbList, setDbList] = useState([]);
//   const [allSecrets, setAllSecrets] = useState([]);
//   const [selectedViewSecrets, setSelectedViewSecrets] =
//     useState("SFTP secrets");

//   const toast = useRef(null);
//   const { getRole } = useUserContext();
//   const role = getRole();
//   // console.log(role);
//   // name: "SFTP secrets",

//   // Render a mandatory field message if there's an error
//   const renderMandatoryFieldMessage = (value, isError) => {
//     return isError ? (
//       <small style={{ color: "red" }}>* {value} is required</small>
//     ) : null;
//   };

//   const [sftpVisible, setSftpVisible] = useState(false);
//   const [sftpValue, setSftpValue] = useState({
//     secretName: "",
//     company: localStorage.getItem("company") || "",
//     env: "",
//     host: "",
//     port: "",
//     username: "",
//     password: "",
//     type: "sftp",
//   });

//   const [sftpError, setSftpError] = useState({
//     secretName: false,
//     // company: false,
//     env: false,
//     host: false,
//     port: false,
//     username: false,
//     password: false,
//   });

//   // State for Database Secret Values
//   const [dbValue, setDbValue] = useState({
//     secretName: "",
//     // company: "",
//     env: "",
//     dburl: "",
//     username: "",
//     password: "",
//   });

//   // State for Database Secret Errors
//   const [dbError, setDbError] = useState({
//     secretName: false,
//     // company: false,
//     env: false,
//     dbUrl: false,
//     username: false,
//     password: false,
//   });

//   // State for Dialog visibility
//   const [dbVisible, setDbVisible] = useState(false);

//   const handleSftpInputChange = (e) => {
//     const { name, value } = e.target;
//     setSftpValue({
//       ...sftpValue,
//       [name]: value, // Dynamically update the corresponding field in the state
//     });
//   };

//   const handleDbInputChange = (e) => {
//     const { name, value } = e.target;
//     setDbValue({
//       ...dbValue,
//       [name]: value, // Update the state dynamically based on the field name
//     });
//   };

//   const env = [{ name: "DEV" }, { name: "QA" }, { name: "PROD" }];

//   const saveHandler = async () => {
//     const requestData = {
//       name: sftpValue.secretName,
//       type: "sftp",
//       company: localStorage.getItem("company") || "",
//       env: sftpValue.env,
//       data: [
//         { name: "host", value: sftpValue.host },
//         { name: "port", value: sftpValue.port },
//         { name: "username", value: sftpValue.username },
//         { name: "password", value: sftpValue.password },
//       ],
//     };

//     try {
//       const axiosInstance = await myAxios(); // Get the axios instance

//       const response = await axiosInstance.put("/secrets/update", requestData);

//       // Show success toast if the response is successful
//       toast.current.show({
//         severity: "success",
//         summary: "Success",
//         detail: "SFTP details updated successfully.",
//         life: 3000,
//       });

//       setSftpVisible(false); // Close the dialog after saving
//       setTimeout(() => {
//         window.location.reload(); // Reloads the page
//       }, 1000);
//     } catch (error) {
//       // Show error toast if the request fails
//       const errorMessage = error.response
//         ? error.response.data.message
//         : "Something went wrong!";
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: errorMessage,
//         life: 3000,
//       });
//     }
//   };

//   const sftpFooterContent = (
//     <div>
//       <Button
//         label="Cancel"
//         icon="pi pi-times"
//         onClick={() => setSftpVisible(false)}
//       />
//       <Button label="Update" icon="pi pi-check" onClick={() => saveHandler()} />
//     </div>
//   );

//   const saveDbHandler = async () => {
//     const requestData = {
//       name: dbValue.secretName,
//       type: "db",
//       company: localStorage.getItem("company") || "",
//       env: dbValue.env,
//       data: [
//         { name: "dburl", value: dbValue.dbUrl },
//         { name: "username", value: dbValue.username },
//         { name: "password", value: dbValue.password },
//       ],
//     };

//     try {
//       const axiosInstance = await myAxios(); // Get the axios instance

//       const response = await axiosInstance.put("/secrets/update", requestData);

//       // Show success toast if the response is successful
//       toast.current.show({
//         severity: "success",
//         summary: "Success",
//         detail: "Database details updated successfully.",
//         life: 3000,
//       });

//       setDbVisible(false);
//       setTimeout(() => {
//         window.location.reload(); // Reloads the page
//       }, 1000);
//     } catch (error) {
//       // Show error toast if the request fails
//       const errorMessage = error.response
//         ? error.response.data.message
//         : "Something went wrong!";
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: errorMessage,
//         life: 3000,
//       });
//     }
//   };
//   const dbFooterContent = (
//     <div>
//       <Button
//         label="Cancel"
//         icon="pi pi-times"
//         onClick={() => setDbVisible(false)}
//       />
//       <Button
//         label="Update"
//         icon="pi pi-check"
//         onClick={() => saveDbHandler()}
//       />
//     </div>
//   );

//   useEffect(() => {
//     async function loadSecrets() {
//       const axiosInstance = await myAxios();
//       const response = await axiosInstance
//         .get(
//           `/secrets/get-secrets/cp-name?company=${
//             localStorage.getItem("company") || ""
//           }`
//         )
//         .then((res) => res.data)
//         .catch((e) => console.log(e));

//       const flattenedSecrets = [];
//       const sftpSecrets = [];
//       const dbSecrets = [];

//       for (let server of response) {
//         for (let serverKey in server) {
//           const serverData = server[serverKey];
//           const flattenedServer = {
//             id: serverKey,
//             name: serverData.name,
//             type: serverData.type,
//             company: serverData.company,
//             env: serverData.env || "",
//             ...serverData.data.reduce((acc, curr) => {
//               acc[curr.name] = curr.value;
//               return acc;
//             }, {}),
//           };
//           flattenedSecrets.push(flattenedServer);

//           if (serverData.type === "sftp") {
//             sftpSecrets.push(flattenedServer);
//           } else if (serverData.type === "db") {
//             dbSecrets.push(flattenedServer);
//           }
//         }
//       }

//       setAllSecrets(flattenedSecrets);
//       setSftpList(sftpSecrets);
//       setDbList(dbSecrets);
//       setSecrets(sftpSecrets); // Default to SFTP secrets
//       setLoading(false);
//     }
//     loadSecrets();
//   }, []);

//   const onGlobalFilterChange = (e) => {
//     const value = e.target.value;
//     let _filters = { ...filters };
//     _filters["global"].value = value;
//     setFilters(_filters);
//     setGlobalFilterValue(value);
//   };

//   const viewSecrets = [{ name: "SFTP secrets" }, { name: "Database secrets" }];

//   const renderHeader = () => {
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//           width: "100%",
//         }}
//       >
//         <div style={{ flex: 1 }} />
//         <h2 style={{ textAlign: "center", flex: 2 }}>{selectedViewSecrets}</h2>
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <Dropdown
//             value={viewSecrets.find((e) => e.name === selectedViewSecrets)}
//             onChange={(e) => {
//               console.log(e.value, "value of secrets ");
//               setSelectedViewSecrets(e.value.name);
//               if (e.value.name === "Database secrets") {
//                 setSecrets(dbList); // Show DB secrets
//               } else {
//                 setSecrets(sftpList); // Show SFTP secrets
//               }
//             }}
//             options={viewSecrets}
//             optionLabel="name"
//             placeholder="Select Secret"
//             className="p-inputtext-m"
//             style={{ width: "13rem", marginRight: "1rem" }}
//           />
//           <IconField iconPosition="left">
//             <InputIcon className="pi pi-search" />
//             <InputText
//               value={globalFilterValue}
//               onChange={onGlobalFilterChange}
//               placeholder="Keyword Search"
//               className="p-inputtext-m"
//             />
//           </IconField>
//         </div>
//       </div>
//     );
//   };

//   const header = renderHeader();

//   // here starts the delete secrets

//   const accept = async (id) => {
//     // Proceed with the delete operation if confirmed

//     const axiosInstance = await myAxios();
//     const apiUrl = `/secrets/delete?secretName=${id}`;

//     try {
//       const response = await axiosInstance.delete(apiUrl);

//       if (response.status === 200) {
//         console.log(response);
//         toast.current.show({
//           severity: "success",
//           summary: "Deleted",
//           detail: `Record with ID ${id} has been deleted.`,
//           life: 3000,
//         });

//         setTimeout(() => {
//           window.location.reload(); // Reloads the page
//         }, 1000);
//       } else {
//         toast.current.show({
//           severity: "error",
//           summary: "Delete Failed",
//           detail: `Failed to delete record with ID ${id}.`,
//           life: 3000,
//         });
//       }
//     } catch (error) {
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: `Error occurred: ${error.message}`,
//         life: 3000,
//       });
//     }
//   };

//   // Function to handle the reject action
//   const reject = () => {
//     toast.current.show({
//       severity: "warn",
//       summary: "Cancelled",
//       detail: "Delete operation cancelled",
//       life: 3000,
//     });
//   };

//   // Function to show the confirmation dialog
//   const confirmDelete = (id) => {
//     confirmDialog({
//       message: `Are you sure you want to delete the record with ID ${id}?`,
//       header: "Delete Confirmation",
//       icon: "pi pi-info-circle",
//       acceptClassName: "p-button-danger",
//       accept: () => accept(id), // Call accept with the id to delete
//       reject, // Call reject to cancel
//     });
//   };

//   ///Here completes the delete

//   const generateColumns = (data) => {
//     if (!data || data.length === 0) return [];

//     const keys = Object.keys(data[0]);

//     const serialNumberColumn = (
//       <Column
//         key="serial"
//         header="Id"
//         body={(rowData, { rowIndex }) => rowIndex + 1} // Serial number starts from 1
//         style={{ minWidth: "8rem" }}
//       />
//     );

//     const columns = keys.map((key) => {
//       if (key === "id" || key === "password" || key === "company") return null;

//       return (
//         <Column
//           key={key}
//           field={key}
//           header={key.charAt(0).toUpperCase() + key.slice(1)} // Capitalize first letter
//           filter
//           filterPlaceholder={`Search by ${key}`}
//           style={{ minWidth: "8rem" }}
//         />
//       );
//     });

//     const actionColumn = role === "ADMIN" && (
//       <Column
//         key="actions"
//         header="Actions"
//         body={(rowData) => (
//           <div className="flex flex-row align-items-center justify-content-center gap-2">
//             <Button
//               tooltip="Edit"
//               tooltipOptions={{ position: "bottom" }}
//               icon="pi pi-pencil"
//               rounded
//               text
//               severity="secondary"
//               onClick={() => editHandler(rowData)} // Pass rowData to editHandler
//               aria-label="Edit"
//             />
//             <Button
//               icon="pi pi-trash"
//               rounded
//               text
//               severity="danger"
//               aria-label="Delete"
//               onClick={() => confirmDelete(rowData.id)} // Handle row deletion
//             />
//           </div>
//         )}
//       />
//     );

//     const editHandler = (rowData) => {
//       if (selectedViewSecrets === "SFTP secrets") {
//         // If "SFTP secrets" is selected, open the SFTP dialog and populate the values
//         setSftpValue({
//           secretName: rowData.name,
//           // company: rowData.company,
//           company: localStorage.getItem("company") || "",
//           env: rowData.env,
//           host: rowData.host,
//           port: rowData.port,
//           username: rowData.username,
//           password: rowData.password,
//         });
//         setSftpVisible(true); // Open SFTP dialog
//       } else if (selectedViewSecrets === "Database secrets") {
//         // If "Database secrets" is selected, open the Database dialog and populate the values
//         console.log(rowData);
//         setDbValue({
//           secretName: rowData.name,
//           company: localStorage.getItem("company") || "",
//           env: rowData.env,
//           dbUrl: rowData.dburl,
//           username: rowData.username,
//           password: rowData.password,
//         });

//         setDbVisible(true); // Open Database dialog
//       }
//     };

//     // Return the serial number column + other dynamic columns + action column
//     return [serialNumberColumn, ...columns, actionColumn];
//   };

//   return (
//     <>
//       <Toast ref={toast} />
//       <ConfirmDialog />
//       <div style={{ marginLeft: "5px", marginBottom: "-25px" }}>
//         <Breadcrumbs />
//       </div>
//       <div className="card">
//         <DataTable
//           value={secrets}
//           paginator
//           rows={10}
//           dataKey="id"
//           filters={filters}
//           filterDisplay="row"
//           loading={loading}
//           globalFilterFields={["name", "host", "username", "dburl"]}
//           header={header}
//           emptyMessage="No secrets found."
//         >
//           {generateColumns(secrets)}
//         </DataTable>
//       </div>
//       {/* Dialog for editing SFTP Secret */}
//       {selectedViewSecrets === "SFTP secrets" && (
//         <Dialog
//           header="SFTP Secret Details"
//           visible={sftpVisible}
//           style={{ width: "30vw" }}
//           onHide={() => setSftpVisible(false)} // Close dialog on hide
//           footer={sftpFooterContent}
//         >
//           <div className="flex flex-column align-items-start">
//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="sftpName">Name of Secrets</label>
//               <div>
//                 {renderMandatoryFieldMessage(
//                   sftpValue.secretName,
//                   sftpError.secretName
//                 )}
//               </div>
//               <InputText
//                 id="sftpName"
//                 name="secretName"
//                 value={sftpValue.secretName}
//                 onChange={handleSftpInputChange}
//                 required
//                 style={{ width: "100%", fontSize: "1.2em" }}
//               />
//             </div>

//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="env">Environment</label>
//               <div>
//                 {renderMandatoryFieldMessage(sftpValue.env, sftpError.env)}
//               </div>
//               <Dropdown
//                 id="env"
//                 name="env"
//                 value={env.find((e) => e.name === sftpValue.env)}
//                 onChange={(e) => {
//                   setSftpValue({
//                     ...sftpValue,
//                     env: e.value.name,
//                   });
//                 }}
//                 options={env}
//                 optionLabel="name"
//                 placeholder="Select an Environment"
//                 style={{ width: "100%", fontSize: "1.2em" }}
//               />
//             </div>

//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="host">SFTP Host</label>
//               <div>
//                 {renderMandatoryFieldMessage(sftpValue.host, sftpError.host)}
//               </div>
//               <InputText
//                 id="host"
//                 name="host"
//                 value={sftpValue.host}
//                 onChange={handleSftpInputChange}
//                 required
//                 style={{ width: "100%", fontSize: "1.2em" }}
//               />
//             </div>

//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="port">Port</label>
//               <div>
//                 {renderMandatoryFieldMessage(sftpValue.port, sftpError.port)}
//               </div>
//               <InputText
//                 id="port"
//                 name="port"
//                 value={sftpValue.port}
//                 onChange={handleSftpInputChange}
//                 required
//                 style={{ width: "100%", fontSize: "1.2em" }}
//               />
//             </div>

//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="username">Username</label>
//               <div>
//                 {renderMandatoryFieldMessage(
//                   sftpValue.username,
//                   sftpError.username
//                 )}
//               </div>
//               <InputText
//                 id="username"
//                 name="username"
//                 value={sftpValue.username}
//                 onChange={handleSftpInputChange}
//                 required
//                 style={{ width: "100%", fontSize: "1.2em" }}
//               />
//             </div>

//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="password">Password</label>
//               <div>
//                 {renderMandatoryFieldMessage(
//                   sftpValue.password,
//                   sftpError.password
//                 )}
//               </div>
//               <div
//                 className="w-full"
//                 style={{ width: "100%", fontSize: "1.2em" }}
//               >
//                 <Password
//                   className="w-full"
//                   id="password"
//                   name="password"
//                   type="password"
//                   toggleMask
//                   value={sftpValue.password}
//                   onChange={handleSftpInputChange}
//                   required
//                 />
//               </div>
//             </div>
//           </div>
//         </Dialog>
//       )}
//       {selectedViewSecrets === "Database secrets" && (
//         <Dialog
//           header="Database Secret Details"
//           visible={dbVisible}
//           style={{ width: "30vw" }}
//           onHide={() => setDbVisible(false)} // Close dialog
//           footer={dbFooterContent} // Footer buttons (Save/Cancel)
//         >
//           <div className="flex flex-column align-items-start">
//             {/* Secret Name */}
//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="dbName">Secret Name</label>
//               <div>
//                 {renderMandatoryFieldMessage(
//                   dbValue.secretName,
//                   dbError.secretName
//                 )}
//               </div>
//               <InputText
//                 id="dbName"
//                 name="secretName"
//                 value={dbValue.secretName}
//                 onChange={handleDbInputChange}
//                 required
//                 style={{ width: "100%", fontSize: "1.2em" }}
//               />
//             </div>

//             {/* Environment Dropdown */}
//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="env">Environment</label>
//               <div>{renderMandatoryFieldMessage(dbValue.env, dbError.env)}</div>
//               <Dropdown
//                 id="env"
//                 name="env"
//                 value={env.find((e) => e.name === dbValue.env)} // Value binding
//                 onChange={(e) => setDbValue({ ...dbValue, env: e.value.name })}
//                 options={env} // Ensure 'env' is defined with available environments
//                 optionLabel="name"
//                 placeholder="Select an Environment"
//                 style={{ width: "100%", fontSize: "1.2em" }}
//               />
//             </div>

//             {/* Database URL */}
//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="dbUrl">Database URL</label>
//               <div>
//                 {renderMandatoryFieldMessage(dbValue.dbUrl, dbError.dbUrl)}
//               </div>
//               <InputText
//                 id="dbUrl"
//                 name="dbUrl"
//                 value={dbValue.dbUrl}
//                 onChange={handleDbInputChange}
//                 required
//                 style={{ width: "100%", fontSize: "1.2em" }}
//               />
//             </div>

//             {/* Username */}
//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="dbUserName">Username</label>
//               <div>
//                 {renderMandatoryFieldMessage(
//                   dbValue.username,
//                   dbError.username
//                 )}
//               </div>
//               <InputText
//                 id="dbUserName"
//                 name="username"
//                 value={dbValue.username}
//                 onChange={handleDbInputChange}
//                 required
//                 style={{ width: "100%", fontSize: "1.2em" }}
//               />
//             </div>

//             {/* Password */}
//             <div
//               className="p-field"
//               style={{ width: "100%", marginBottom: "1rem" }}
//             >
//               <label htmlFor="dbPassword">Password</label>
//               <div>
//                 {renderMandatoryFieldMessage(
//                   dbValue.password,
//                   dbError.password
//                 )}
//               </div>
//               <Password
//                 className="w-full"
//                 id="dbPassword"
//                 name="password"
//                 type="text"
//                 toggleMask
//                 value={dbValue.password}
//                 onChange={handleDbInputChange}
//                 required
//               />
//             </div>
//           </div>
//         </Dialog>
//       )}
//     </>
//   );
// }

import React, { useState, useEffect, useRef } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button"; // For action buttons (Edit/Delete)
import Breadcrumbs from "../components/Breadcrumb";
import { createAxiosInstance as myAxios } from "../services/Helper";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Password } from "primereact/password";
import { useUserContext } from "../components/contextAPIs/UserContext";
import { classNames } from "primereact/utils";
import { ColorPicker } from "primereact/colorpicker";

export default function ViewSecret() {
  const [secrets, setSecrets] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    host: { value: null, matchMode: FilterMatchMode.CONTAINS },
    port: { value: null, matchMode: FilterMatchMode.EQUALS },
    username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [sftpList, setSftpList] = useState([]);
  const [dbList, setDbList] = useState([]);
  const [allSecrets, setAllSecrets] = useState([]);
  const [selectedViewSecrets, setSelectedViewSecrets] =
    useState("SFTP secrets");

  const toast = useRef(null);
  const { getRole } = useUserContext();
  const role = getRole();

  const renderMandatoryFieldMessage = (value, isError) => {
    return isError ? (
      <small style={{ color: "red" }}>* {value} is required</small>
    ) : null;
  };

  const [sftpVisible, setSftpVisible] = useState(false);
  const [sftpValue, setSftpValue] = useState({
    secretName: "",
    company: localStorage.getItem("company") || "",
    type: "sftp",

    host_dev: "",
    port_dev: "",
    username_dev: "",
    password_dev: "",

    host_qa: "",
    port_qa: "",
    username_qa: "",
    password_qa: "",

    host_prod: "",
    port_prod: "",
    username_prod: "",
    password_prod: "",
  });

  const [sftpError, setSftpError] = useState({
    secretName: false,
    // company: false,
    // env: false,
    host: false,
    port: false,
    username: false,
    password: false,
  });

  // State for Database Secret Values
  const [dbValue, setDbValue] = useState({
    secretName: "",
    company: localStorage.getItem("company") || "",
    type: "db",

    dburl_dev: "",
    username_dev: "",
    password_dev: "",

    dburl_qa: "",
    username_qa: "",
    password_qa: "",

    dburl_prod: "",
    username_prod: "",
    password_prod: "",
  });

  // State for Database Secret Errors
  const [dbError, setDbError] = useState({
    secretName: false,
    dburl_dev: false,
    username_dev: false,
    password_dev: false,

    dburl_qa: false,
    username_qa: false,
    password_qa: false,

    dburl_prod: false,
    username_prod: false,
    password_prod: false,
  });

  // State for Dialog visibility
  const [dbVisible, setDbVisible] = useState(false);

  const handleSftpInputChange = (e) => {
    const { name, value } = e.target;
    setSftpValue({
      ...sftpValue,
      [name]: value, // Dynamically update the corresponding field in the state
    });
  };

  const handleDbInputChange = (e) => {
    const { name, value } = e.target;
    setDbValue({
      ...dbValue,
      [name]: value, // Update the state dynamically based on the field name
    });
  };

  // const env = [{ name: "DEV" }, { name: "QA" }, { name: "PROD" }];

  const saveHandler = async () => {
    const requestData = {
      name: sftpValue.secretName,
      type: "sftp",
      company: localStorage.getItem("company") || "",
      dev: {
        host: sftpValue.host_dev,
        port: sftpValue.port_dev,
        username: sftpValue.username_dev,
        password: sftpValue.password_dev,
      },
      qa: {
        host: sftpValue.host_qa,
        port: sftpValue.port_qa,
        username: sftpValue.username_qa,
        password: sftpValue.password_qa,
      },
      prod: {
        host: sftpValue.host_prod,
        port: sftpValue.port_prod,
        username: sftpValue.username_prod,
        password: sftpValue.password_prod,
      },
    };

    try {
      const axiosInstance = await myAxios(); // Get the axios instance

      const response = await axiosInstance.put("/secrets/update", requestData);

      // Show success toast if the response is successful
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "SFTP details updated successfully.",
        life: 3000,
      });

      setSftpVisible(false); // Close the dialog after saving
      setTimeout(() => {
        window.location.reload(); // Reloads the page
      }, 1000);
    } catch (error) {
      // Show error toast if the request fails
      const errorMessage = error.response
        ? error.response.data.message
        : "Something went wrong!";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        life: 3000,
      });
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const viewSecrets = [{ name: "SFTP secrets" }, { name: "Database secrets" }];
  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ flex: 1 }} />
        <h2 style={{ textAlign: "center", flex: 2 }}>{selectedViewSecrets}</h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Dropdown
            value={viewSecrets.find((e) => e.name === selectedViewSecrets)}
            onChange={(e) => {
              console.log(e.value, "value of secrets ");
              setSelectedViewSecrets(e.value.name);
              if (e.value.name === "Database secrets") {
                setSecrets(dbList); // Show DB secrets
              } else {
                setSecrets(sftpList); // Show SFTP secrets
              }
            }}
            options={viewSecrets}
            optionLabel="name"
            placeholder="Select Secret"
            className="p-inputtext-m"
            style={{ width: "13rem", marginRight: "1rem" }}
          />
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
              className="p-inputtext-m"
            />
          </IconField>
          ;
        </div>
      </div>
    );
  };

  const saveDbHandler = async () => {
    const requestData = {
      name: dbValue.secretName,
      type: "db",
      company: localStorage.getItem("company") || "",

      dev: {
        dburl: dbValue.dburl_dev,
        username: dbValue.username_dev,
        password: dbValue.password_dev,
      },
      qa: {
        dburl: dbValue.dburl_qa,
        username: dbValue.username_qa,
        password: dbValue.password_qa,
      },
      prod: {
        dburl: dbValue.dburl_prod,
        username: dbValue.username_prod,
        password: dbValue.password_prod,
      },
    };

    try {
      const axiosInstance = await myAxios(); // Get the axios instance

      const response = await axiosInstance.put("/secrets/update", requestData);

      // Show success toast if the response is successful
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Database details updated successfully.",
        life: 3000,
      });

      setDbVisible(false);
      setTimeout(() => {
        window.location.reload(); // Reloads the page
      }, 1000);
    } catch (error) {
      // Show error toast if the request fails
      const errorMessage = error.response
        ? error.response.data.message
        : "Something went wrong!";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        life: 3000,
      });
    }
  };

  const sftpFooterContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setSftpVisible(false)}
      />
      <Button label="Update" icon="pi pi-check" onClick={() => saveHandler()} />
    </div>
  );

  const dbFooterContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setDbVisible(false)}
      />
      <Button
        label="Update"
        icon="pi pi-check"
        onClick={() => saveDbHandler()}
      />
    </div>
  );

  useEffect(() => {
    async function loadSecrets() {
      // Start loading
      setLoading(true);

      const axiosInstance = await myAxios();

      try {
        const response = await axiosInstance.get(
          `/secrets/get-secrets/cp-name?company=${
            localStorage.getItem("company") || ""
          }`
        );

        if (response.status === 200) {
          const allSecrets = response.data;
          const sftpSecrets = [];
          const dbSecrets = [];

          allSecrets.forEach((serverObj) => {
            const serverName = Object.keys(serverObj)[0];
            const serverDetails = serverObj[serverName];
            if (serverDetails.type === "sftp") {
              sftpSecrets.push(serverDetails);
            } else if (serverDetails.type === "db") {
              dbSecrets.push(serverDetails);
            }
          });

          // Set state directly without storing in intermediate arrays
          setAllSecrets(allSecrets);
          setSftpList(sftpSecrets);
          setDbList(dbSecrets);

          // Set default secrets based on the selected type
          if (selectedViewSecrets === "SFTP secrets") {
            setSecrets(sftpSecrets);
          } else {
            setSecrets(dbSecrets);
          }
        }
      } catch (error) {
        console.log(error);
        // If there's an error, set loading to false after some time
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        return; // Exit early if error
      }

      // In case the response is successful, set loading to false
      setLoading(false);
    }

    loadSecrets();

    // Optionally set a fallback timeout for loading if needed
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 5000); // Timeout after 5 seconds if loading is not finished

    // Clean up the timeout if the effect is unmounted before the timeout completes
    return () => clearTimeout(loadingTimeout);
  }, []);

  // useEffect(() => {
  //   async function loadSecrets() {
  //     // Initialize secrets arrays
  //     let flattenedSecrets = [];
  //     let sftpSecrets = [];
  //     let dbSecrets = [];
  //     // Start loading
  //     setLoading(true);

  //     const axiosInstance = await myAxios();

  //     try {
  //       const response = await axiosInstance.get(
  //         `/secrets/get-secrets/cp-name?company=${
  //           localStorage.getItem("company") || ""
  //         }`
  //       );

  //       if (response.status === 200) {
  //         flattenedSecrets = response.data;
  //         flattenedSecrets.map((serverObj) => {
  //           const serverName = Object.keys(serverObj)[0];
  //           const serverDetails = serverObj[serverName];
  //           if (serverDetails.type === "sftp") {
  //             sftpSecrets.push(serverDetails);
  //           } else if (serverDetails.type === "db") {
  //             dbSecrets.push(serverDetails);
  //           }
  //         });

  //         setAllSecrets(flattenedSecrets);
  //         setSftpList(sftpSecrets);
  //         setDbList(dbSecrets);

  //         // Set default secrets based on type
  //         if (selectType === "sftp") {
  //           setSecrets(sftpSecrets);
  //         } else {
  //           setSecrets(dbSecrets);
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 2000);
  //       return;
  //     }

  //     setLoading(false);
  //   }

  //   loadSecrets();

  //   const loadingTimeout = setTimeout(() => {
  //     setLoading(false);
  //   }, 5000);

  //   return () => clearTimeout(loadingTimeout);
  // }, []);

  // useEffect(() => {
  //   async function loadSecrets() {
  //     const axiosInstance = await myAxios();
  //     const response = await axiosInstance
  //       .get(
  //         `/secrets/get-secrets/cp-name?company=${
  //           localStorage.getItem("company") || ""
  //         }`
  //       )
  //       .then((res) => res)
  //       .catch((e) => console.log(e));

  //     console.log(response);

  //     let flattenedSecrets = [];
  //     let sftpSecrets = [];
  //     let dbSecrets = [];

  //     if (response.status === 200) {
  //       flattenedSecrets = response.data;
  //       flattenedSecrets.map((serverObj) => {
  //         const serverName = Object.keys(serverObj)[0];
  //         const serverDetails = serverObj[serverName];
  //         if (serverDetails.type === "sftp") {
  //           sftpSecrets.push(serverDetails);
  //         } else if (serverDetails.type === "db") {
  //           dbSecrets.push(serverDetails);
  //         }
  //       });

  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 2000);
  //     }

  //     setAllSecrets(flattenedSecrets);
  //     setSftpList(sftpSecrets);
  //     setDbList(dbSecrets);
  //     setSecrets(sftpSecrets); // Default to SFTP secrets
  //     // setLoading(false);

  //     if (selectType === "sftp") {
  //       setSecrets(sftpSecrets);
  //     } else {
  //       setSecrets(dbSecrets);
  //     }
  //   }
  //   loadSecrets();
  //   // setTimeout(() => {
  //   //   setLoading(false);
  //   // }, 2000);
  // }, []);

  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span className="vertical-align-middle ml-2 font-bold line-height-3">
          {data.name}
        </span>
      </React.Fragment>
    );
  };
  const headerDevBodyTemplate = (data) => {
    return (
      <>
        {selectedViewSecrets === "SFTP secrets" ? (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.host}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.port}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.username}
            </p>
            <Password
              className="vertical-align-middle ml-2  line-height-3"
              value={data.dev.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        ) : (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.dburl}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.dev.username}
            </p>
            <Password
              className="vertical-align-middle ml-2  line-height-3"
              value={data.dev.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        )}
      </>
    );
  };
  const headerQABodyTemplate = (data) => {
    // console.log(data, " heaader dev body template ");
    return (
      <>
        {selectedViewSecrets === "SFTP secrets" ? (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.host}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.port}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.username}
            </p>
            <Password
              className="vertical-align-middle ml-2  line-height-3"
              value={data.qa.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        ) : (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.dburl}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.qa.username}
            </p>
            <Password
              className="vertical-align-middle ml-2  line-height-3"
              value={data.qa.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        )}
      </>
    );
  };
  const headerProdBodyTemplate = (data) => {
    // console.log(data, " heaader dev body template ");
    return (
      <>
        {selectedViewSecrets === "SFTP secrets" ? (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.host}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.port}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.username}
            </p>
            <Password
              className="vertical-align-middle ml-2  line-height-3"
              value={data.prod.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        ) : (
          <div>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.dburl}
            </p>
            <p className="vertical-align-middle ml-2  line-height-3">
              {data.prod.username}
            </p>
            <Password
              className="vertical-align-middle ml-2  line-height-3"
              value={data.prod.password}
              toggleMask={role === "ADMIN"}
              disabled
            ></Password>
          </div>
        )}
      </>
    );
  };

  const editHandler = (rowData) => {
    // console.log(rowData);
    if (selectedViewSecrets === "SFTP secrets") {
      //         // If "SFTP secrets" is selected, open the SFTP dialog and populate the values
      setSftpValue({
        secretName: rowData.name,
        company: localStorage.getItem("company") || "",
        type: "sftp",

        host_dev: rowData.dev.host,
        port_dev: rowData.dev.port,
        username_dev: rowData.dev.username,
        password_dev: rowData.dev.password,

        host_qa: rowData.qa.host,
        port_qa: rowData.qa.port,
        username_qa: rowData.qa.username,
        password_qa: rowData.qa.password,

        host_prod: rowData.prod.host,
        port_prod: rowData.prod.port,
        username_prod: rowData.prod.username,
        password_prod: rowData.prod.password,
      });
      setSftpVisible(true); // Open SFTP dialog
    } else if (selectedViewSecrets === "Database secrets") {
      // If "Database secrets" is selected, open the Database dialog and populate the values
      // console.log(rowData);
      setDbValue({
        secretName: rowData.name,
        company: localStorage.getItem("company") || "",
        type: "db",

        dburl_dev: rowData.dev.dburl,
        username_dev: rowData.dev.username,
        password_dev: rowData.dev.password,

        dburl_qa: rowData.qa.dburl,
        username_qa: rowData.qa.username,
        password_qa: rowData.qa.password,

        dburl_prod: rowData.prod.dburl,
        username_prod: rowData.prod.username,
        password_prod: rowData.prod.password,
      });

      setDbVisible(true); // Open Database dialog
    }
  };

  const accept = async (name) => {
    // Proceed with the delete operation if confirmed

    // console.log(name);
    const axiosInstance = await myAxios();
    const apiUrl = `/secrets/delete?secretName=${name}`;

    try {
      const response = await axiosInstance.delete(apiUrl);

      if (response.status === 200) {
        // console.log(response);
        toast.current.show({
          severity: "success",
          summary: "Deleted",
          detail: `Record with ID ${name} has been deleted.`,
          life: 3000,
        });

        setTimeout(() => {
          window.location.reload(); // Reloads the page
        }, 1000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Delete Failed",
          detail: `Failed to delete record with ID ${name}.`,
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `Error occurred: ${error.message}`,
        life: 3000,
      });
    }
  };

  // Function to handle the reject action
  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Cancelled",
      detail: "Delete operation cancelled",
      life: 3000,
    });
  };

  // Function to show the confirmation dialog
  const confirmDelete = (name) => {
    confirmDialog({
      message: `Are you sure you want to delete the record with ID ${name}?`,
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => accept(name), // Call accept with the id to delete
      reject, // Call reject to cancel
    });
    // console.log(name);
  };

  const actionColumn = role === "ADMIN" && (
    <Column
      style={{ width: "10%" }}
      key="actions"
      header="Actions"
      body={(rowData) => (
        <div className="flex flex-row align-items-center justify-content-center gap-2">
          <Button
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            icon="pi pi-pencil"
            rounded
            text
            severity="secondary"
            onClick={() => editHandler(rowData)} // Pass rowData to editHandler
            aria-label="Edit"
          />
          <Button
            icon="pi pi-trash"
            rounded
            text
            severity="danger"
            aria-label="Delete"
            onClick={() => confirmDelete(rowData.name)} // Handle row deletion
          />
        </div>
      )}
    />
  );

  const [expandedRows, setExpandedRows] = useState([]);

  const header = renderHeader();

  return (
    <>
      <div style={{ marginLeft: "5px", marginBottom: "-25px" }}>
        <Breadcrumbs />
      </div>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="card">
        <DataTable
          value={secrets}
          rowGroupMode="subheader"
          header={header}
          groupRowsBy="name"
          sortMode="single"
          sortField="name"
          filters={filters}
          filterDisplay="row"
          loading={loading}
          rowGroupHeaderTemplate={headerTemplate}
          sortOrder={1}
          expandableRowGroups
          expandedRows={expandedRows}
          onRowToggle={(e) => {
            setExpandedRows(e.data);
          }}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            field="name"
            header="Name"
            style={{ width: "22.5%" }}
            filter
            filterPlaceholder="search by name"
          ></Column>

          {/* <Column field="type" style={{ width: "16%" }} header="Type"></Column> */}
          <Column
            field="dev"
            header="Dev"
            style={{ width: "22.5%" }}
            body={headerDevBodyTemplate}
          ></Column>
          <Column
            field="qa"
            header="QA"
            style={{ width: "22.5%" }}
            body={headerQABodyTemplate}
          ></Column>
          <Column
            field="prod"
            header="PROD"
            style={{ width: "22.5%" }}
            body={headerProdBodyTemplate}
          ></Column>
          {/* Directly render actionColumn as a JSX element */}
          {actionColumn}
        </DataTable>
      </div>
      {selectedViewSecrets === "SFTP secrets" && (
        // <Dialog
        //   header="SFTP Secret Details"
        //   visible={sftpVisible}
        //   style={{ width: "30vw" }}
        //   onHide={() => setSftpVisible(false)} // Close dialog on hide
        //   footer={sftpFooterContent}
        // >

        // </Dialog>

        <Dialog
          header="SFTP Secret Details"
          visible={sftpVisible}
          style={{ width: "60vw" }} // Adjust the width as needed
          onHide={() => setSftpVisible(false)}
          footer={sftpFooterContent}
        >
          <div className="flex flex-column align-items-start col-12">
            <div
              className="p-field"
              style={{ width: "100%", marginBottom: "1rem" }}
            >
              <label htmlFor="sftpName">Name of Secrets</label>
              <div>
                {renderMandatoryFieldMessage(
                  sftpValue.secretName,
                  sftpError.secretName
                )}
              </div>
              <InputText
                id="sftpName"
                name="secretName"
                onChange={handleSftpInputChange}
                required
                style={{ width: "100%", fontSize: "1.2em" }}
                value={sftpValue.secretName}
                disabled
              />
            </div>

            <div
              className="p-field col-12"
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "space-between",
              }}
            >
              {/* Column 1 for PROD */}
              <div style={{ flex: "1" }} className="col-4">
                <label htmlFor="env">Environment</label>

                <InputText
                  id="env"
                  name="env"
                  value={"DEV"}
                  style={{ width: "100%", fontSize: "1.2em" }}
                  disabled
                />
                <label htmlFor="host_dev">SFTP Host</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.host_dev,
                    sftpError.host_dev
                  )}
                </div>
                <InputText
                  id="host_dev"
                  name="host_dev"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={sftpValue.host_dev}
                />
                <label htmlFor="sftpPort_dev">Port</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.port_dev,
                    sftpError.port_dev
                  )}
                </div>
                <InputText
                  id="sftpPort_dev"
                  name="port_dev"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={sftpValue.port_dev}
                />
                <label htmlFor="sftpUserName_dev">Username</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.username_dev,
                    sftpError.username_dev
                  )}
                </div>
                <InputText
                  id="sftpUserName_dev"
                  name="username_dev"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={sftpValue.username_dev}
                />
                <label htmlFor="sftpPassword_dev">Password</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.password_dev,
                    sftpError.password_dev
                  )}
                </div>
                <Password
                  className="w-full"
                  id="sftpPassword_dev"
                  name="password_dev"
                  type="password"
                  toggleMask
                  onChange={handleSftpInputChange}
                  required
                  value={sftpValue.password_dev}
                />
              </div>

              {/* Column 2 for DEV */}
              <div style={{ flex: "1" }} className="col-4">
                <label htmlFor="env">Environment</label>
                <div>
                  {renderMandatoryFieldMessage(sftpValue.env, sftpError.env)}
                </div>
                <InputText
                  id="env"
                  name="env"
                  value={"QA"}
                  style={{ width: "100%", fontSize: "1.2em" }}
                  disabled
                />
                <label htmlFor="host_qa">SFTP Host</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.host_qa,
                    sftpError.host_qa
                  )}
                </div>
                <InputText
                  id="host_qa"
                  name="host_qa"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={sftpValue.host_qa}
                />
                <label htmlFor="sftpPort_qa">Port</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.port_qa,
                    sftpError.port_qa
                  )}
                </div>
                <InputText
                  id="sftpPort_qa"
                  name="port_qa"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={sftpValue.port_qa}
                />
                <label htmlFor="sftpUserName_qa">Username</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.username_qa,
                    sftpError.username_qa
                  )}
                </div>
                <InputText
                  id="sftpUserName_qa"
                  name="username_qa"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={sftpValue.username_qa}
                />
                <label htmlFor="sftpPassword_qa">Password</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.password_qa,
                    sftpError.password_qa
                  )}
                </div>
                <Password
                  className="w-full"
                  id="sftpPassword_qa"
                  name="password_qa"
                  type="password"
                  toggleMask
                  onChange={handleSftpInputChange}
                  required
                  value={sftpValue.password_qa}
                />
              </div>

              {/* Column 3 for QA */}
              <div style={{ flex: "1" }} className="col-4">
                <label htmlFor="env">Environment</label>
                <div>
                  {renderMandatoryFieldMessage(sftpValue.env, sftpError.env)}
                </div>
                <InputText
                  id="env"
                  name="env"
                  value={"PROD"}
                  style={{ width: "100%", fontSize: "1.2em" }}
                  disabled
                />
                <label htmlFor="host_prod">SFTP Host</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.host_prod,
                    sftpError.host_prod
                  )}
                </div>
                <InputText
                  id="host_prod"
                  name="host_prod"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={sftpValue.host_prod}
                />
                <label htmlFor="sftpPort_prod">Port</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.port_prod,
                    sftpError.port_prod
                  )}
                </div>
                <InputText
                  id="sftpPort_prod"
                  name="port_prod"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={sftpValue.port_prod}
                />
                <label htmlFor="sftpUserName_prod">Username</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.username_prod,
                    sftpError.username_prod
                  )}
                </div>
                <InputText
                  id="sftpUserName_prod"
                  name="username_prod"
                  onChange={handleSftpInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={sftpValue.username_prod}
                />
                <label htmlFor="sftpPassword_prod">Password</label>
                <div>
                  {renderMandatoryFieldMessage(
                    sftpValue.password_prod,
                    sftpError.password_prod
                  )}
                </div>
                <Password
                  className="w-full"
                  id="sftpPassword_prod"
                  name="password_prod"
                  type="password"
                  toggleMask
                  onChange={handleSftpInputChange}
                  required
                  value={sftpValue.password_prod}
                />
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {selectedViewSecrets === "Database secrets" && (
        <Dialog
          header="Database Secret Details"
          visible={dbVisible}
          style={{ width: "60vw" }} // Adjust the width as needed
          onHide={() => setDbVisible(false)}
          footer={dbFooterContent}
        >
          {/* <div className="flex flex-column align-items-start"> */}
          <div className="flex flex-column align-items-start col-12">
            <div
              className="p-field"
              style={{ width: "100%", marginBottom: "1rem" }}
            >
              <label htmlFor="dbName">Secret Name</label>
              <div>
                {renderMandatoryFieldMessage(
                  dbValue.secretName,
                  dbError.secretName
                )}
              </div>
              <InputText
                id="dbName"
                name="secretName"
                onChange={handleDbInputChange}
                required
                style={{ width: "100%", fontSize: "1.2em" }}
                value={dbValue.secretName}
                disabled
              />
            </div>

            <div
              className="p-field col-12"
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "space-between",
              }}
            >
              {/* Column 1 for PROD */}
              <div style={{ flex: "1" }} className="col-4">
                <label htmlFor="host">Environment</label>
                <InputText
                  id="env"
                  name="env"
                  value={"DEV"}
                  style={{ width: "100%", fontSize: "1.2em" }}
                  disabled
                />
                <label htmlFor="dburl_dev">Database URL</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.dburl_dev,
                    dbError.dburl_dev
                  )}
                </div>
                <InputText
                  id="dburl_dev"
                  name="dburl_dev"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={dbValue.dburl_dev}
                />
                <label htmlFor="dbUserName_dev">Username</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.username_dev,
                    dbError.username_dev
                  )}
                </div>
                <InputText
                  id="dbUserName_dev"
                  name="username_dev"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={dbValue.username_dev}
                />
                <label htmlFor="dbPassword_dev">Password</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.password_dev,
                    dbError.password_dev
                  )}
                </div>
                <Password
                  className="w-full"
                  id="dbPassword_dev"
                  name="password_dev"
                  type="password"
                  toggleMask
                  onChange={handleDbInputChange}
                  required
                  value={dbValue.password_dev}
                />
              </div>
              <div style={{ flex: "1" }} className="col-4">
                <label htmlFor="host">Environment</label>
                <InputText
                  id="env"
                  name="env"
                  value={"QA"}
                  style={{ width: "100%", fontSize: "1.2em" }}
                  disabled
                />
                <label htmlFor="dburl_qa">Database URL</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.dburl_qa,
                    dbError.dburl_qa
                  )}
                </div>
                <InputText
                  id="dburl_qa"
                  name="dburl_qa"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={dbValue.dburl_qa}
                />
                <label htmlFor="dbUserName_qa">Username</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.username_qa,
                    dbError.username_qa
                  )}
                </div>
                <InputText
                  id="dbUserName_qa"
                  name="username_qa"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={dbValue.username_qa}
                />
                <label htmlFor="dbPassword_qa">Password</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.password_qa,
                    dbError.password_qa
                  )}
                </div>
                <Password
                  className="w-full"
                  id="dbPassword_qa"
                  name="password_qa"
                  type="password_qa"
                  toggleMask
                  onChange={handleDbInputChange}
                  required
                  value={dbValue.password_qa}
                />
              </div>
              <div style={{ flex: "1" }} className="col-4">
                <label htmlFor="host">Environment</label>
                <InputText
                  id="env"
                  name="env"
                  value={"PROD"}
                  style={{ width: "100%", fontSize: "1.2em" }}
                  disabled
                />
                <label htmlFor="dburl_prod">Database URL</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.dburl_prod,
                    dbError.dburl_prod
                  )}
                </div>
                <InputText
                  id="dburl_prod"
                  name="dburl_prod"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={dbValue.dburl_prod}
                />
                <label htmlFor="dbUserName_prod">Username</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.username_prod,
                    dbError.username_prod
                  )}
                </div>
                <InputText
                  id="dbUserName_prod"
                  name="username_prod"
                  onChange={handleDbInputChange}
                  required
                  style={{ width: "100%", fontSize: "1.2em" }}
                  value={dbValue.username_prod}
                />
                <label htmlFor="dbPassword_prod">Password</label>
                <div>
                  {renderMandatoryFieldMessage(
                    dbValue.password_prod,
                    dbError.password_prod
                  )}
                </div>
                <Password
                  className="w-full"
                  id="dbPassword_prod"
                  name="password_prod"
                  type="password_prod"
                  toggleMask
                  onChange={handleDbInputChange}
                  required
                  value={dbValue.password_prod}
                />
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
