import React, { useRef, useState, useEffect } from "react";
import "./LoginSignup.css";
import { Form } from "reactstrap";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputOtp } from "primereact/inputotp";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import images from "../../assets/images";
import  { createAxiosInstance as myAxios}  from "../../services/Helper";

const VerifyOtp = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [timer, setTimer] = useState(180); // 3 minutes = 180 seconds
  const [canResend, setCanResend] = useState(false);
  let email = localStorage.getItem("otpemail");

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => setTimer(timer - 1), 1000);
    } else {
      setCanResend(true);
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  const handleConfirm = async (e) => {
    e.preventDefault();

    // let resp = await myAxios
    //   .post(`http://localhost:8080/api/users/verify/otp?email=${email}&otp=${code}`)
    //   .then((res) => res.data)
    //   .catch((e) => e.response.data);
    const axiosInstance = await myAxios()
    let resp = await axiosInstance
      .post(`users/verify/otp?email=${email}&otp=${code}`)
      .then((res) => res.data)
      .catch((e) => e.response.data);

    // console.log(resp);

    let status = resp.status;

    if (status === 404) {
      toast.current.show({
        severity: "error",
        summary: "OTP Expired",
        detail: resp.message,
        life: 1000,
      });

      setTimeout(() => {
        navigate("/forgot-password");
      }, 2000);
    } else if (status === 400) {
      toast.current.show({
        severity: "error",
        summary: "Miss Match",
        detail: resp.message,
        life: 1000,
      });
    } else {
      toast.current.show({
        severity: "success",
        summary: "OTP Verified",
        detail: resp.message,
        life: 1000,
      });

      setTimeout(() => {
        navigate("/changepassword");
      }, 2000);
    }
  };

  const handleResendOtp = async () => {

    // let resp = await  myAxios.post(`http://localhost:8080/api/users/forgot/password?email=${email}`).then((res)=> res.data).catch((e)=> e.response.data)
    const axiosInstance = await myAxios()
    let resp = await  axiosInstance.post(`users/forgot/password?email=${email}`)
    .then((res)=> res.data).catch((e)=> e.response.data)
    // console.log(resp);
    // console.log(resp.status);
 
    if(resp.status === 404 ){
   
       toast.current.show({
         severity : "error",
         summary: "Invalid Email",
         detail : resp.message,
         life : 1000,
       });
 
    //  console.log(resp.message );
    }
   else if(resp.status === 200) {
    
     toast.current.show({
       severity : "success",
       summary: "OTP Sent",
       detail : resp.message,
       life : 1000,
     });

        setTimer(180); // Reset timer to 3 minutes
        setCanResend(false);

    }



  };
  

  return (
    <>
      <Toast ref={toast} />
      <div
        className="flex flex-column w-screen h-screen overflow-y-auto surface-50"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="w-12 md:w-4 lg:w-4 m-auto">
          <div className="flex justify-content-center w-full">
            <Card className="w-full shadow-3 hover:shadow-5 border-round-lg">
              <Form>
                <div className="flex flex-column align-items-center justify-content-center gap-3 p-4 w-full">
                  <img
                    src={images.logo}
                    alt="logo"
                    className="login-logo"
                    style={{ marginBottom: "1rem" }}
                  />
                  <span className="text-2xl font-bold my-3 text-center w-full">
                    Code Verification
                  </span>

                  <div
                    className="w-full my-2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <InputOtp
                      value={code}
                      onChange={(e) => setCode(e.value)}
                      integerOnly
                      numInput={4} // Ensure it accepts 4 digits
                    />
                  </div>

                  <div className="flex flex-column align-items-stretch justify-content-start gap-3 w-full">
                    <Button
                      label="Verify"
                      type="button"
                      severity="primary"
                      onClick={handleConfirm}
                      className="border-round-lg"
                    />
                  </div>
                  {canResend ? (
                    <div
                      // to=""
                      className="no-underline"
                      style={{ color: "#007bff", textDecoration: "none" , cursor:"pointer" }}
                      onClick={handleResendOtp}
                    >
                      Resend Code
                    </div>
                  ) : (
                    <span>{`Resend Code in ${Math.floor(timer / 60)}:${(
                      timer % 60
                    )
                      .toString()
                      .padStart(2, "0")}`}</span>
                  )}
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;