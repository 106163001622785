// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
  }
  
  .name {
    text-align: center;
    align-self: center;
    margin-bottom: 20px; /* Optional: Add some space between the text and the bottom of the page */
  }
  `, "",{"version":3,"sources":["webpack://./src/DocumentReview/src/components/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,8BAA8B;IAC9B,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB,EAAE,yEAAyE;EAChG","sourcesContent":["body {\r\n    display: flex;\r\n    min-height: 100vh;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    margin: 0;\r\n  }\r\n  \r\n  .name {\r\n    text-align: center;\r\n    align-self: center;\r\n    margin-bottom: 20px; /* Optional: Add some space between the text and the bottom of the page */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
