export const viewRolesmtm = [
  { name: "DEV", fetchApi: (ruleId) => `mtm/sch/mtm/getByID/${ruleId}` },
  { name: "QA", fetchApi: (ruleId) => `qa/mtm/sch/mtm/getByID/${ruleId}` },
  { name: "PROD", fetchApi: (ruleId) => `prod/mtm/sch/mtm/getByID/${ruleId}` },
];

export const viewRolesmtd = [
  { name: "DEV", fetchApi: (ruleId) => `mtd/sch/getByID/${ruleId}` },
  { name: "QA", fetchApi: (ruleId) => `qa/mtd/sch/getByID/${ruleId}` },
  { name: "PROD", fetchApi: (ruleId) => `prod/mtd/sch/getByID/${ruleId}` },
];

export const viewRolesdtm = [
  { name: "DEV", fetchApi: (ruleId) => `dtm/sch/getByID/${ruleId}` },
  { name: "QA", fetchApi: (ruleId) => `qa/dtm/sch/getByID/${ruleId}` },
  { name: "PROD", fetchApi: (ruleId) => `prod/dtm/sch/getByID/${ruleId}` },
];
export const viewRolesdtd = [
  { name: "DEV", fetchApi: (ruleId) => `dtd/sch/getByID/${ruleId}` },
  { name: "QA", fetchApi: (ruleId) => `qa/dtd/sch/getByID/${ruleId}` },
  { name: "PROD", fetchApi: (ruleId) => `prod/dtd/sch/getByID/${ruleId}` },
];

export const transferDept = [
  { name: "QA", code: "qa" },
  { name: "PROD", code: "prod" },
];
