import React, { Fragment, useRef } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth-slice";
import "./menu.css";

export default function MenuComponent() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.login);
  const file = useSelector((state) => state.table.name);
  const menu = useRef(null);
  const toast = useRef(null);
  const profile = auth ? auth.user : "Profile";
  const items = [
    {
      label: profile,
      items: [],
    },
  ];

  // if (file) {
  //   items.push({
  //     label: "logout",
  //     icon: "pi pi-sign-out",
  //     command: () => {
  //       localStorage.removeItem("id");
  //       localStorage.removeItem("user");
  //       dispatch(authActions.setAuth(null));
  //     },
  //   });
  // } else {
  //   items.push({
  //     label: "logout",
  //     icon: "pi pi-sign-out",
  //     command: () => {
  //       localStorage.removeItem("id");
  //       localStorage.removeItem("user");
  //       dispatch(authActions.setAuth(null));
  //     },
  //   });
  // }

  return (
    <Fragment>
      <div className="card flex justify-content-center m-0">
        <Toast ref={toast}></Toast>
        <Menu model={items} popup ref={menu} />
        <Button
          icon="pi pi-user"
          onClick={(e) => menu.current.toggle(e)}
          style={{
            color: "#ffffff",
            background: "#f39058",
            borderColor: "#f39058",
          }}
        />
      </div>
    </Fragment>
  );
}