import { createSlice } from "@reduxjs/toolkit";

const myFilesSlice = createSlice({
  name: "myFiles",
  initialState: {
    visible: false,
  },
  reducers: {
    setModel(state, action) {
      state.visible = !state.visible;
    },
  },
});

export const myFilesActions = myFilesSlice.actions;

export default myFilesSlice;
