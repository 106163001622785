import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";
import { ContextTimeScheduling } from "./ContextTimeScheduling";
import { useLocation } from "react-router-dom";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import { Messages } from "primereact/messages";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useUserContext } from "../contextAPIs/UserContext";
import { apiConfig } from "./apiConfig";
const TimeScheduling = () => {
  const options = [
    { id: "once", label: "Only Once" },
    { id: "every_minutes", label: "Minutes" },
    { id: "every_hours", label: "Hours" },
    { id: "every_days", label: "Days" },
    { id: "every_months", label: "Months" },
    { id: "every_weeks", label: "Weeks" },
    { id: "every_years", label: "Years" },
  ];
  const monthOptions = [
    { label: "JAN", value: "JAN" },
    { label: "FEB", value: "FEB" },
    { label: "MAR", value: "MAR" },
    { label: "APR", value: "APR" },
    { label: "MAY", value: "MAY" },
    { label: "JUN", value: "JUN" },
    { label: "JUL", value: "JUL" },
    { label: "AUG", value: "AUG" },
    { label: "SEP", value: "SEP" },
    { label: "OCT", value: "OCT" },
    { label: "NOV", value: "NOV" },
    { label: "DEC", value: "DEC" },
  ];

  const dayOptions = [
    { label: "MON", value: "MON" },
    { label: "TUE", value: "TUE" },
    { label: "WED", value: "WED" },
    { label: "THU", value: "THU" },
    { label: "FRI", value: "FRI" },
    { label: "SAT", value: "SAT" },
    { label: "SUN", value: "SUN" },
  ];

  const options1 = [
    { id: "CST", label: "America/Chicago" },
    { id: "EST", label: "Asia/Kolkata" },
    { id: "ACT", label: "America/Cuiaba" },
  ];

  const { getRole } = useUserContext();
  const role = getRole();
  const toastTopRight = useRef(null);
  const toastTopCenter = useRef(null);
  const toastmsg = useRef(null);
  const navigate = useNavigate();
  const { openDialog, setOpenDialog, contextFormData, selectedViewAs } =
    useContext(ContextTimeScheduling);
  const [selectedTime, setSelectedTime] = useState(null);
  // const [selectValidDetailsFrequency, setSelectValidDetailsFrequency] =
  //   useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [timeZone, settimeZone] = useState(null);
  const [selectedOption, setSelectedOption] = useState("once");
  const [values, setValues] = useState({
    hours: "",
    minutes: "",
    days: "",
    weeks: "",
    months: "",
  });
  //this is for the edit timeScheduling Page
  const location = useLocation();

  const convertToInteger = (value) => (value !== "" ? parseInt(value, 10) : "");
  //this schedule is for update Rules
  const isEditMode = location.pathname.includes("edit");
  useEffect(() => {
    //if it is update dialog this gonna set the values geting from the backend
    if (isEditMode) {
      setSelectedOption(contextFormData.frequencyType);
      setValues({
        hours: convertToInteger(contextFormData.hours),
        minutes: convertToInteger(contextFormData.minutes),
        days: convertToInteger(contextFormData.days),
        weeks: contextFormData.weeks,
        months: contextFormData.months,
      });
      setStartDate(new Date(contextFormData.startDate));
      setEndDate(new Date(contextFormData.endDate));
      settimeZone(contextFormData.timeZone);
      let inputDate = new Date(contextFormData.startDate);
      setSelectedTime(inputDate);
    } else {
      // if this is a create job dialog than set the values to default
      setValues({
        hours: "",
        minutes: "",
        days: "",
        weeks: "",
        months: "",
      });
      setStartDate("");
      setEndDate("");
      settimeZone("");
      setSelectedTime("");
    }
  }, [contextFormData, location.pathname]);

  const today = new Date();
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSelect1 = (option) => {
    settimeZone(option.label);
  };

  const handleChange = (e, field) => {
    setValues({ ...values, [field]: e.value });
    if (e.value) {
      setValidationErrors({ ...validationErrors, [field]: false });
    }
  };

  const formatDate = (date) => {
    if (!date) return ""; // Handle null case gracefully
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatTime = (time) => {
    return time
      ? `${time.getHours().toString().padStart(2, "0")}:${time
          .getMinutes()
          .toString()
          .padStart(2, "0")}`
      : ""; // Format time to "HH:mm"
  };

  useEffect(() => {
    setValues((prevValues) => {
      const resetValues = {
        hours: "",
        minutes: "",
        weeks: "",
        months: "",
        days: "",
      };

      if (selectedOption === "every_minutes") {
        resetValues.minutes = prevValues.minutes;
      }
      if (selectedOption === "every_hours") {
        resetValues.hours = prevValues.hours;
      }
      if (selectedOption === "every_days") {
        resetValues.days = prevValues.days;
      }
      if (selectedOption === "every_weeks") {
        resetValues.weeks = prevValues.weeks;
      }

      if (selectedOption === "every_months") {
        resetValues.days = prevValues.days;
      }
      if (selectedOption === "every_years") {
        resetValues.days = prevValues.days;
        resetValues.months = prevValues.months;
      }

      return resetValues;
    });
  }, [selectedOption]);

  const combinedData = {
    ...contextFormData,
    ...values,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    timeZone,
    selectedTime: formatTime(selectedTime),
    email: localStorage.getItem("userId"),
    frequencyType: selectedOption,
  };

  //error handling functioin
  const validateFields = () => {
    const errors = {};

    // Check required fields based on selectedOption
    if (selectedOption === "every_minutes" && !values.minutes) {
      errors.minutes = true;
    }
    if (selectedOption === "every_hours" && !values.hours) {
      errors.hours = true;
    }
    if (
      ["every_days", "every_months", "every_years"].includes(selectedOption) &&
      !values.days
    ) {
      errors.days = true;
    }
    if (selectedOption === "every_weeks" && !values.weeks) {
      errors.weeks = true;
    }
    if (selectedOption === "every_years" && !values.months) {
      errors.months = true;
    }

    if (!selectedTime) errors.selectedTime = true;
    if (!timeZone) errors.timeZone = true;
    if (!startDate) errors.startDate = true;
    if (!endDate) errors.endDate = true;

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const [validationErrors, setValidationErrors] = useState({});
  //submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      // Proceed with submission logic
      // toastTopRight.current.show({
      //   severity: "error",
      //   summary: "Fields Required",
      //   detail: "Fields should not be Empty",
      // });;
      return;
    }

    try {
      //if this is the update rule page
      if (isEditMode) {
        try {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const startDate = new Date(combinedData.startDate);
          const endDate = new Date(combinedData.endDate);

          // Validate dates
          if (startDate < today || endDate < today) {
            toastTopCenter.current.show({
              severity: "error",
              summary: "Error",
              detail:
                "While Updateing Job Start Date and End Date must not be before today date",
              life: 3000,
            });
            return;
          }

          const idCode = contextFormData.ruleId.substring(0, 3);
          const axiosInstance = await myAxios();
          // console.log("selected view as" + selectedViewAs);
          if (selectedViewAs === "DEV") {
            await axiosInstance.put(
              `${idCode}/update/${contextFormData.ruleId}`,
              combinedData
            );
          } else {
            await axiosInstance.put(
              `${selectedViewAs.toLowerCase()}/${idCode}/update/${
                contextFormData.ruleId
              }`,
              combinedData
            );
          }
          toastTopRight.current.show({
            severity: "info",
            summary: "Info",
            detail: "Job Updated Successfully.",
            life: 3000,
          });

          navigate("/home");
        } catch (e) {
          if (e.response && e.response.status === 401) {
            toastTopRight.current.show({
              severity: "info",
              summary: "Info",
              detail: "Token Expired Please login again .",
              life: 3000,
            });
            navigate("/login");
          } else {
            console.error(e);
          }
        }
      }
      //if this is normal schedule page
      else {
        const { pathname } = location;
        // console.log(pathname);
        const endpointConfig = apiConfig[pathname];
        if (endpointConfig) {
          const axiosInstance = await myAxios();
          // const encryptedData = await handleEncryptedRequest(combinedData);
          const apiEndpoint = endpointConfig[role] || endpointConfig.default;

          await axiosInstance.post(apiEndpoint, combinedData);

          toastTopRight.current.show({
            severity: "success",
            summary: "Success",
            detail: endpointConfig.successMessage,
            life: 3000,
          });

          navigate("/home");
        }
      }
    } catch (e) {
      if (e.response && e.response.status === 401) {
        toastTopRight.current.show({
          severity: "error",
          summary: "Token Expired ",
          detail: "Try Login Again!",
          life: 2000,
        });
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        console.error(e);
      }
    }

    setOpenDialog(false);
  };

  const footerContent = (
    <div className="grid col-12  grid-nogutter gap-5 py-2">
      <Button
        type="submit"
        severity="primary"
        label={isEditMode ? "Update" : "Done"}
        icon="pi pi-check"
        onClick={handleSubmit}
        autoFocus
      />
    </div>
  );

  const handleSelectFrequency = (e) => {
    const frequencyValue = e.value;
    // console.log(frequencyValue);
    setSelectedOption(frequencyValue.id);
  };

  return (
    <>
      <Toast ref={toastTopRight} position="top-right" />
      <Dialog
        header="Define Recurrence"
        visible={openDialog}
        style={{ width: "45rem" }}
        onHide={() => {
          if (!openDialog) return;
          setOpenDialog(false);
        }}
        footer={footerContent}
      >
        <form>
          <Toast ref={toastTopCenter} position="top-center" />
          <Messages ref={toastmsg} />
          <div className="col-12 grid grid-nogutter gap-5 py-5">
            <div className="col-8">
              <div className="p-0">
                <FloatLabel>
                  <Dropdown
                    inputId="frequency_dropdown"
                    placeholder="Choose Frequency"
                    value={options.find(
                      (option) => option.id === selectedOption
                    )}
                    // value={}
                    options={options}
                    onChange={handleSelectFrequency}
                    optionLabel="label"
                    className="w-full"
                  />
                  <label htmlFor="frequency_dropdown">Frequency</label>
                </FloatLabel>
              </div>
            </div>
          </div>
          {selectedOption === "every_minutes" && (
            <div className="col-6 grid grid-nogutter gap-5 py-2">
              <div className="col-12">
                <FloatLabel>
                  <InputNumber
                    id="minutesInput"
                    inputId="minutesInput"
                    value={values.minutes}
                    onValueChange={(e) => handleChange(e, "minutes")}
                    min={1}
                    max={59}
                    className="w-full"
                    // style={getInputStyle("minutes")}
                    invalid={validationErrors.minutes}
                  />
                  <label htmlFor="minutesInput">Minutes</label>
                </FloatLabel>
              </div>
            </div>
          )}

          {selectedOption === "every_hours" && (
            <div className="col-6 grid grid-nogutter gap-5 py-2">
              <div className="col-12">
                <FloatLabel>
                  <InputNumber
                    id="hoursInput"
                    inputId="hoursInput"
                    value={values.hours}
                    onValueChange={(e) => handleChange(e, "hours")}
                    min={1}
                    max={23}
                    className="w-full"
                    invalid={validationErrors.hours}
                  />
                  <label htmlFor="hoursInput">Hours</label>
                </FloatLabel>
              </div>
            </div>
          )}

          {(selectedOption === "every_days" ||
            selectedOption === "every_months" ||
            selectedOption === "every_years") && (
            <div className="grid col-6 grid-nogutter gap-5 py-2">
              <div className="col-12">
                <FloatLabel>
                  <InputNumber
                    id="daysInput"
                    inputId="daysInput"
                    value={values.days}
                    onValueChange={(e) => handleChange(e, "days")}
                    min={1}
                    max={31}
                    className="w-full"
                    invalid={validationErrors.days}
                  />
                  <label htmlFor="daysInput">
                    {selectedOption === "every_days"
                      ? "Days of Difference"
                      : "Date of Month"}
                  </label>
                </FloatLabel>
                {selectedOption === "every_days" && (
                  <small id="username-help" className="red">
                    Note: 1 means everyday
                  </small>
                )}
              </div>
            </div>
          )}
          {selectedOption === "every_weeks" && (
            <div className="col-6 grid grid-nogutter gap-5 py-2">
              <div className="col-12">
                <Dropdown
                  placeholder="Select a Day"
                  id="day"
                  value={values.weeks}
                  options={dayOptions}
                  onChange={(e) => handleChange(e, "weeks")}
                  className="w-full"
                  invalid={validationErrors.weeks}
                />
              </div>
            </div>
          )}
          {selectedOption === "every_years" && (
            <div className="grid col-12 grid-nogutter gap-5 py-2">
              <div className="col-6">
                <Dropdown
                  id="month"
                  value={values.months}
                  options={monthOptions}
                  onChange={(e) => handleChange(e, "months")}
                  placeholder="Select a Month"
                  // className="w-full"
                  invalid={validationErrors.months}
                  style={{ width: "20.2rem" }}
                />
              </div>
            </div>
          )}

          <br />
          <div className="grid col-12 ">
            <div className="grid col-6 grid-nogutter gap-5 py-2">
              <div className="col-12">
                <FloatLabel>
                  <Calendar
                    id="timeInput"
                    value={selectedTime}
                    onChange={(e) => {
                      setSelectedTime(
                        e.value
                          ? new Date(
                              e.value.getTime() - e.value.getSeconds() * 1000
                            )
                          : null
                      );
                    }}
                    timeOnly
                    hourFormat="24"
                    showIcon
                    showTime
                    placeholder="HH:mm"
                    mask="99:99"
                    className="w-full"
                    invalid={validationErrors.selectedTime}
                  />
                  <label htmlFor="endDate">Time 24-hour format</label>
                </FloatLabel>
              </div>
            </div>
            <div className="grid col-6 grid-nogutter gap-5 py-2">
              <div className="col-12">
                <FloatLabel>
                  <Dropdown
                    value={options1.find((option) => option.label === timeZone)}
                    options={options1}
                    onChange={(e) => handleSelect1(e.value)}
                    placeholder="Time Zone"
                    optionLabel="label"
                    // style={{ width: "300px" }}
                    className="w-full"
                    invalid={validationErrors.timeZone}
                  />
                  <label htmlFor="endDate">Time Zone</label>
                </FloatLabel>
              </div>
            </div>
          </div>

          <br />
          <div className="grid col-12">
            <div className="col-6 grid grid-nogutter gap-5 py-2">
              <div className="col-12">
                <FloatLabel>
                  <Calendar
                    id="startDate"
                    inputId="startDate"
                    value={startDate}
                    onChange={handleStartDateChange}
                    minDate={today}
                    showIcon
                    showButtonBar
                    placeholder="mm/dd/yyyy"
                    className="w-full"
                    invalid={validationErrors.startDate}
                  />
                  <label htmlFor="startDate">From</label>
                </FloatLabel>
              </div>
            </div>

            <div className="col-6 grid grid-nogutter gap-5 py-2">
              <div className="col-12">
                <FloatLabel>
                  <Calendar
                    id="endDate"
                    inputId="endDate"
                    minDate={startDate || today}
                    value={endDate}
                    onChange={handleEndDateChange}
                    showIcon
                    showButtonBar
                    placeholder="mm/dd/yyyy"
                    className="w-full"
                    invalid={validationErrors.endDate}
                  />
                  <label htmlFor="endDate">To</label>
                </FloatLabel>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default TimeScheduling;
