// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavContainer{
    display: flex;
  justify-content: space-between;
}

.p-menuitem-text{
    color: #f39058;
    font-weight: bold;
     text-align: center;
}

.p-menubar {
  justify-content: space-between !important;
  
}
.p-menubar ul.p-menubar-root-list {
  flex-grow: 1 !important;
  justify-content: center !important;
  align-items: center !important;
}
.p-menubar .p-menubar-end {
  margin: 0 !important;
}

.p-menuitem-text{
  color: #f39058 !important;
}`, "",{"version":3,"sources":["webpack://./src/DocumentReview/src/components/Navbar/navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf,8BAA8B;AAChC;;AAEA;IACI,cAAc;IACd,iBAAiB;KAChB,kBAAkB;AACvB;;AAEA;EACE,yCAAyC;;AAE3C;AACA;EACE,uBAAuB;EACvB,kCAAkC;EAClC,8BAA8B;AAChC;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".NavContainer{\r\n    display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.p-menuitem-text{\r\n    color: #f39058;\r\n    font-weight: bold;\r\n     text-align: center;\r\n}\r\n\r\n.p-menubar {\r\n  justify-content: space-between !important;\r\n  \r\n}\r\n.p-menubar ul.p-menubar-root-list {\r\n  flex-grow: 1 !important;\r\n  justify-content: center !important;\r\n  align-items: center !important;\r\n}\r\n.p-menubar .p-menubar-end {\r\n  margin: 0 !important;\r\n}\r\n\r\n.p-menuitem-text{\r\n  color: #f39058 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
