import React from 'react'

const Contact = () => {
  return (
    <div>
      <h1>This is Contact Page...</h1>
    </div>
  )
}

export default Contact;
