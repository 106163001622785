// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* border: 2px solid red; */
  margin: 1rem;
  margin-top: -1rem;
  height: 100vh;
}

.rulelist td .start_button {
  background: none;
  color: black;
  border: none;
  padding: 0.25rem; /* Adjust padding as needed */
}

.maindiv .rulelist {
  width: 100%;
  max-width: 66rem;
  margin: auto;
  box-sizing: border-box;
  position: relative;
  margin-top: 2rem;
}

.pagination-wrapper {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

/* New CSS for the Actions column */
.actions-column {
  white-space: nowrap;
}

.actions-column .start_button {
  display: inline-block; /* Align buttons horizontally */
  margin-right: 0.5rem; /* Add some space between buttons */
}
.red {
  color: red;
  width: 60px;
}
.box-view {
  width: 250px;
}
.green {
  color: green;
  width: 60px;
}

.show {
  display: inline-block;
}
.hide {
  display: none;
}
.box-170 {
  width: 170px;
}
`, "",{"version":3,"sources":["webpack://./src/components/LandingPageComponent/RuleList.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iCAAiC;EACjC,2BAA2B;EAC3B,YAAY;EACZ,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,gBAAgB,EAAE,6BAA6B;AACjD;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,2BAA2B;AAC7B;;AAEA,mCAAmC;AACnC;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB,EAAE,+BAA+B;EACtD,oBAAoB,EAAE,mCAAmC;AAC3D;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;AACd","sourcesContent":[".table-responsive {\r\n  overflow-x: auto;\r\n  -webkit-overflow-scrolling: touch;\r\n  /* border: 2px solid red; */\r\n  margin: 1rem;\r\n  margin-top: -1rem;\r\n  height: 100vh;\r\n}\r\n\r\n.rulelist td .start_button {\r\n  background: none;\r\n  color: black;\r\n  border: none;\r\n  padding: 0.25rem; /* Adjust padding as needed */\r\n}\r\n\r\n.maindiv .rulelist {\r\n  width: 100%;\r\n  max-width: 66rem;\r\n  margin: auto;\r\n  box-sizing: border-box;\r\n  position: relative;\r\n  margin-top: 2rem;\r\n}\r\n\r\n.pagination-wrapper {\r\n  position: absolute;\r\n  bottom: 10px;\r\n  left: 50%;\r\n  transform: translateX(-50%);\r\n}\r\n\r\n/* New CSS for the Actions column */\r\n.actions-column {\r\n  white-space: nowrap;\r\n}\r\n\r\n.actions-column .start_button {\r\n  display: inline-block; /* Align buttons horizontally */\r\n  margin-right: 0.5rem; /* Add some space between buttons */\r\n}\r\n.red {\r\n  color: red;\r\n  width: 60px;\r\n}\r\n.box-view {\r\n  width: 250px;\r\n}\r\n.green {\r\n  color: green;\r\n  width: 60px;\r\n}\r\n\r\n.show {\r\n  display: inline-block;\r\n}\r\n.hide {\r\n  display: none;\r\n}\r\n.box-170 {\r\n  width: 170px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
