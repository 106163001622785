// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-filter-column{
    width: 100%
}


input.p-inputtext.p-component.p-column-filter {
    width: 200px;
    height: 40px;
}

.p-datatable-tfoot{
    display:none
}

.p-column-header-content{
    color: #000000;
}

.p-column-title{
    /* color: #f00; */
    font-family: 'Arial Rounded MT Bold',Serif, "Times New Roman", Times, "Arial";
    font-size: 19px;
}
.p-icon{
    color: #000000;
}


.input-width {
    width: 550px;
}

.box {
    width: 620px;
}
.input-container {
    display: flex;
    align-items: center;
  }


.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-family: 'Arial Rounded MT Bold',Serif, "Times New Roman", Times, "Arial";
    font-size : 18.5px;
    color: #000000;
}

.input-container-1 {
    display: flex;
    align-items: center;
  }



.input-number {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.input-number label {
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/DocumentReview/src/components/table/table.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;;AAGA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI;AACJ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,6EAA6E;IAC7E,eAAe;AACnB;AACA;IACI,cAAc;AAClB;;;AAGA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;EACrB;;;AAGF;IACI,gBAAgB;IAChB,yBAAyB;IACzB,uBAAuB;IACvB,kBAAkB;IAClB,6EAA6E;IAC7E,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;EACrB;;;;AAIF;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".p-filter-column{\r\n    width: 100%\r\n}\r\n\r\n\r\ninput.p-inputtext.p-component.p-column-filter {\r\n    width: 200px;\r\n    height: 40px;\r\n}\r\n\r\n.p-datatable-tfoot{\r\n    display:none\r\n}\r\n\r\n.p-column-header-content{\r\n    color: #000000;\r\n}\r\n\r\n.p-column-title{\r\n    /* color: #f00; */\r\n    font-family: 'Arial Rounded MT Bold',Serif, \"Times New Roman\", Times, \"Arial\";\r\n    font-size: 19px;\r\n}\r\n.p-icon{\r\n    color: #000000;\r\n}\r\n\r\n\r\n.input-width {\r\n    width: 550px;\r\n}\r\n\r\n.box {\r\n    width: 620px;\r\n}\r\n.input-container {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n\r\n\r\n.p-datatable .p-datatable-tbody > tr > td {\r\n    text-align: left;\r\n    border: 1px solid #dee2e6;\r\n    border-width: 0 0 1px 0;\r\n    padding: 1rem 1rem;\r\n    font-family: 'Arial Rounded MT Bold',Serif, \"Times New Roman\", Times, \"Arial\";\r\n    font-size : 18.5px;\r\n    color: #000000;\r\n}\r\n\r\n.input-container-1 {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n\r\n\r\n\r\n.input-number {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: flex-start;\r\n}\r\n\r\n.input-number label {\r\n    margin-bottom: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
