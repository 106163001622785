import React, { useContext, useState, createContext } from "react";

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Initial user state
  // const [role,setRoles] = useState(null)

  const login = (newUser) => setUser(newUser);
  const logout = () => {
    setUser(null);
  };

  const getRole = () => {
    return localStorage.getItem("userRole");
  };

  return (
    <UserContext.Provider value={{ user, login, logout, getRole }}>
      {children}
    </UserContext.Provider>
  );
};
export const useUserContext = () => useContext(UserContext);
