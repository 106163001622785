// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/***************Create Rules***************************/

.rule-box-3 {
  width: 100%;
  height: 450px;
}

.iconimage {
  margin: 0 auto;
}

.iconimage,
.iconimage img {
  width: 128px;
  height: 128px;
}

.iconimage img {
  object-fit: contain;
  object-position: center;
}

.servicename {
  color: #1689e0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the spinner appears on top */
}
`, "",{"version":3,"sources":["webpack://./src/components/RuleComponent/createrule.css"],"names":[],"mappings":"AAAA,uDAAuD;;AAEvD;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;;EAEE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,sCAAsC;AACvD","sourcesContent":["/***************Create Rules***************************/\r\n\r\n.rule-box-3 {\r\n  width: 100%;\r\n  height: 450px;\r\n}\r\n\r\n.iconimage {\r\n  margin: 0 auto;\r\n}\r\n\r\n.iconimage,\r\n.iconimage img {\r\n  width: 128px;\r\n  height: 128px;\r\n}\r\n\r\n.iconimage img {\r\n  object-fit: contain;\r\n  object-position: center;\r\n}\r\n\r\n.servicename {\r\n  color: #1689e0;\r\n}\r\n\r\n.loading-overlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  background: rgba(0, 0, 0, 0.5);\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  z-index: 9999; /* Ensure the spinner appears on top */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
