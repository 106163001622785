// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
}
.card {
  margin-top: 30px;
}

.underline-text {
  display: inline-block;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.3s;
  cursor: pointer;
  /* text-decoration: underline; */
}
`, "",{"version":3,"sources":["webpack://./src/components/History/FileDetails.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,qBAAqB;AACtC;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,iCAAiC;EACjC,eAAe;EACf,gCAAgC;AAClC","sourcesContent":[".spinner-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 100vh; /* Adjust as needed */\r\n}\r\n.card {\r\n  margin-top: 30px;\r\n}\r\n\r\n.underline-text {\r\n  display: inline-block;\r\n  padding: 0.5rem;\r\n  border-radius: 4px;\r\n  transition: background-color 0.3s;\r\n  cursor: pointer;\r\n  /* text-decoration: underline; */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
