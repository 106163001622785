import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import Breadcrumbs from "../../components/Breadcrumb";
import { ContextTimeScheduling } from "../../components/scheduleComponent/ContextTimeScheduling";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { viewRolesdtm, transferDept } from "./viewRoleApis";

export default function DbToMountRules() {
  const [ruleData, setRuleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  ///Here state for test connection
  // const [loadingTest, setLoadingTest] = useState(false);
  const [visible, setVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  //added for limit query.
  const [limitErrorMessage, setLimitErrorMessage] = useState("");
  const [isLimitValid, setIsLimitValid] = useState(true);

  const location = useLocation();
  const { ruleId, viewRole } = location.state || {};
  const toast = useRef(null);
  const { setOpenDialog, setcontextFormData, setSelectedViewAs } = useContext(
    ContextTimeScheduling
  );

  const { dbResponse, sftpResponse } = location.state || {};
  const [selectedSftpOption, setSelectedSftpOption] = useState("");
  const [selectedDBOption, setSelectedDBOption] = useState("");

  const [formData, setFormData] = useState({
    sourceSecretName: "",
    server1: "",
    port: "",
    sftpUser: "",
    sftpPass: "",
    filePath: "",
    fileName: "",
    tableName: "",
    targetSecretName: "",
    jobname: "",
    dbUrl: "",
    dbUser: "",
    dbPass: "",
    archiveLocation: "",
    minutes: "",
    hours: "",
    months: "",
    days: "",
    weeks: "",
    frequencyType: "",
    timeZone: "",
    startDate: "",
    startTime: "",
    endDate: "",
    ruleId: "",
    queryText: "",
    addQuery: false,
    jiraTicketNumber: "",
    version: "",
  });

  useEffect(() => {
    if (ruleId === undefined) navigate("/home");
    setSelectedViewAs(viewRole);
    const fetchData = async () => {
      try {
        // // console.log("This is the rule Id :" + ruleId);
        const axiosInstance = await myAxios();
        if (viewRole === "DEV") {
          const response = await axiosInstance.get(
            `${viewRolesdtm[0].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        } else if (viewRole === "QA") {
          const response = await axiosInstance.get(
            `${viewRolesdtm[1].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        } else if (viewRole === "PROD") {
          const response = await axiosInstance.get(
            `${viewRolesdtm[2].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        }
      } catch (error) {
        console.log(error);
        console.log(error.response);
        let errorValue = error.response ? error.response : null;
        console.log(errorValue);
        if (errorValue == null) {
          navigate("/login");
        } else if (errorValue.status === 401) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);

          toast.current.show({
            severity: "error",
            summary: "Token Expired ",
            detail: "Session Time Out ",
            life: 2000,
          });
        } else if (errorValue.status === 403) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);

          toast.current.show({
            severity: "error",
            summary: "Access Denied",
            detail: "Try Login Again.",
            life: 2000,
          });
        }

        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (ruleId) {
      fetchData();
    }
  }, [ruleId]);

  ////////////////////////////////////////////////////
  //this is the handle dropdown
  //Dropdown
  const handleDBSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedDBOption(selectedName);

    const selectedData = dbResponse.find(
      (item) => item.name === selectedName.name
    );
    if (selectedData) {
      let environmentData;

      // Check the viewRole and select corresponding environment data
      if (viewRole === "QA") {
        environmentData = selectedData["qa"];
      } else if (viewRole === "PROD") {
        environmentData = selectedData["prod"];
      } else if (viewRole === "DEV") {
        environmentData = selectedData["dev"];
      }
      setFormData((prevState) => ({
        ...prevState,
        sourceSecretName: selectedName.name || "",
        dbUrl: environmentData?.dburl || "",
        dbUser: environmentData?.username || "",
        dbPass: environmentData?.password || "",
      }));
    }
  };

  //Dropdown
  const handleSftpSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedSftpOption(selectedName);

    const selectedData = sftpResponse.find(
      (item) => item.name === selectedName.name
    );
    if (selectedData) {
      let environmentData;

      // Check the viewRole and select corresponding environment data
      if (viewRole === "QA") {
        environmentData = selectedData["qa"];
      } else if (viewRole === "PROD") {
        environmentData = selectedData["prod"];
      } else if (viewRole === "DEV") {
        environmentData = selectedData["dev"];
      }
      setFormData((prevState) => ({
        ...prevState,
        targetSecretName: selectedName.name || "",
        server1: environmentData?.host || "",
        port: environmentData?.port || "",
        sftpUser: environmentData?.username || "",
        sftpPass: environmentData?.password || "",
      }));
    }
  };

  //////////////////////////////////
  // this function is used to set the data .
  // setSelectedSftpOption(selectedName);
  const setServerData = (secretName) => {
    sftpResponse.map((resp) => {
      if (resp.name === secretName) {
        setSelectedSftpOption(resp);
        // console.log(resp)
      }
    });
  };

  const setServerData2 = (secretName2) => {
    dbResponse.map((resp) => {
      if (resp.name === secretName2) {
        setSelectedDBOption(resp);
        // console.log(resp)
      }
    });
  };

  useEffect(() => {
    if (ruleData) {
      // console.log(ruleData, " getting from the edit form data ");
      const startTime = ruleData.startDate
        ? ruleData.startDate.split("T")[1]
        : "";

      setServerData(ruleData.targetSecretName);
      setServerData2(ruleData.sourceSecretName);

      setFormData({
        sourceSecretName: ruleData.sourceSecretName || "",
        server1: ruleData.server || "",
        port: ruleData.port.toString() || "",
        sftpUser: ruleData.sftpUser || "",
        sftpPass: ruleData.sftpPass || "",
        filePath: ruleData.filePath || "",
        fileName: ruleData.fileName || "",
        jobname: ruleData.jobname || "",
        targetSecretName: ruleData.targetSecretName || "",
        tableName: ruleData.tableName || "",
        dbUrl: ruleData.dbUrl || "",
        dbUser: ruleData.dbUser || "",
        dbPass: ruleData.dbPass || "",
        archiveLocation: ruleData.archiveLocation || "",
        minutes: ruleData.minutes || "",
        hours: ruleData.hours || "",
        months: ruleData.months || "",
        days: ruleData.days || "",
        weeks: ruleData.weeks || "",
        frequencyType: ruleData.frequencyType || "",
        timeZone: ruleData.timeZone || "",
        startDate: ruleData.startDate || "",
        startTime: startTime || "",
        endDate: ruleData.endDate || "",
        ruleId: ruleData.id || "",
        queryText: ruleData.queryText || "",
        addQuery: !!ruleData.queryText,
        jiraTicketNumber: ruleData.jiraTicketNumber || "",
        version: ruleData.version || "",
      });
    }
  }, [ruleData]);

  ///////////////////////////////////////////////////////////////////////////////////////////
  // added for test connection for sftp1

  const [sftpServer1, setSftpServer1] = useState({
    server1: "",
    port1: "",
    user1: "",
    pass1: "",
    filePath: "",
  });

  // added for the database connection

  const [databaseConnectionTest, setDatabaseConnectionTest] = useState({
    dbUrl: "",
    dbUser: "",
    dbPass: "",
    tableName: "",
  });

  //useEffect on outside
  useEffect(() => {
    if (sftpServer1) {
      if (checkSftpValid()) {
        setLoading(true);
        setVisible(true);
        callTestSFTP();
      }
    }
  }, [sftpServer1]);

  // useEffect on OutSide the function for Testing DBConnection Test
  useEffect(() => {
    if (databaseConnectionTest) {
      if (checkDatabaseConnectionValid()) {
        setLoading(true);
        setVisible(true);
        callTestDatabase();
      }
    }
  }, [databaseConnectionTest]);

  const checkDatabaseConnectionValid = () => {
    for (const key in databaseConnectionTest) {
      if (databaseConnectionTest[key] === "") {
        return false;
      }
    }

    return true;
  };

  const checkSftpValid = () => {
    for (const key in sftpServer1) {
      if (sftpServer1[key] === "") {
        return false; // Return false if any field is empty
      }
    }
    return true;
  };

  const callTestSFTP = async () => {
    try {
      const axiosInstance = await myAxios();
      let sftp_connect = await axiosInstance
        .post("/test/con/sftp", sftpServer1)
        .then((res) => res.data);

      // // console.log(sftp_connect);

      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      setResponseMessage(message);
      setResponseStatus(status);

      if (status === 401) {
        navigate("/login");
      }
    } catch (e) {
      let errorResponse = e.response.data;
      setResponseMessage(errorResponse.messages || "Failed to connect.");
      setResponseStatus(errorResponse.status || "Error");
    } finally {
      setLoading(false);
    }
  };

  // handle the database  ApI call here
  const callTestDatabase = async () => {
    try {
      const axiosInstance = await myAxios();
      let sftp_connect = await axiosInstance
        .post("/test/con/db", databaseConnectionTest)
        .then((res) => res.data)
        .catch((e) => e.response.data);
      // // console.log(sftp_connect);

      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      // update code added here for message and status
      setResponseMessage(message);
      setResponseStatus(status);

      if (status === 401) {
        navigate("/login");
      }
    } catch (e) {
      let errorResponse = e.response.data;
      setResponseMessage(errorResponse.messages || "Failed to connect.");
      setResponseStatus(errorResponse.status || "Error");
    } finally {
      setLoading(false);
    }
  };

  // handle test connection
  const handleTestConnection = async (e, buttonType) => {
    e.preventDefault();

    if (buttonType === 1) {
      if (
        formData.filePath.trim() === "" ||
        formData.fileName.trim() === "" ||
        formData.server1.trim() === "" ||
        formData.port.trim() === "" ||
        formData.sftpUser.trim() === "" ||
        formData.sftpPass.trim() === ""
      ) {
        // // console.log(checkSftpValid(), " sftp details ");
        // // console.log(sftpServer1);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill the Target's required fields .",
          life: 3000,
        });
        return;
      } else {
        setSftpServer1({
          server1: formData.server1,
          port1: formData.port,
          user1: formData.sftpUser,
          pass1: formData.sftpPass,
          // filePath: formData.filePath + "/" + formData.fileName,
          filePath: formData.filePath,
        });
      }
    }
  };

  // handle test connection
  const handleTestForDBConnection = async (e, buttonType) => {
    e.preventDefault();

    if (buttonType === 1) {
      if (
        formData.dbPass.trim() === "" ||
        formData.dbUser.trim() === "" ||
        formData.dbUrl.trim() === "" ||
        formData.tableName.trim() === ""
      ) {
        // // // console.log(checkDatabaseConnectionValid() , " sftp details ") ;
        // // console.log(databaseConnectionTest);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill the Source's required fields .",
          life: 3000,
        });
        return;
      } else {
        setDatabaseConnectionTest({
          dbUrl: formData.dbUrl,
          dbUser: formData.dbUser,
          dbPass: formData.dbPass,
          tableName: formData.tableName,
        });
      }
    }
  };

  // handle Test condition
  /////////////////////////////////////////////////////////////////////////////////////////

  const validateForm = () => {
    // console.log(formData.addQuery , formData.queryText , " addquery and insert query ")
    // console.log(formData)
    if (
      formData.addQuery &&
      (formData.queryText === undefined || formData.queryText === "")
    ) {
      return false;
    }

    const listofValidateFields = [
      "jobname",
      "sourceSecretName",
      "targetSecretName",
      "fileName",
      "tableName",
      "filePath",
      "archiveLocation",
    ];

    for (let e of listofValidateFields) {
      if (formData[e] === "") {
        // console.log(e, "is file name ", e === "fileName");
        return false;
      }
    }

    return true; // All fields are filled
  };

  const handleClick = (e) => {
    e.preventDefault();

    // adding for limit query error
    // console.log(isLimitValid,)
    if (!isLimitValid) {
      toast.current.show({
        severity: "error",
        summary: "SQL Query Limit ",
        detail: "You can take Limited Query Amount else Opt for offset",
        life: 2000,
      });
      return;
    }

    // console.log(validateForm(), "form is valid or not ")
    if (!validateForm()) {
      toast.current.show({
        severity: "error",
        summary: "Validation Error",
        detail: "Please fill all required fields.",
      });
    } else {
      setcontextFormData(formData);
      setOpenDialog(true); // This should trigger the dialog to open
    }
  };

  //////////////////////////////////////////////////////////////////////

  // useEffect to see the values
  useEffect(() => {
    // Regular expression to find the number after the LIMIT keyword
    const limitMatch = formData.queryText.match(/LIMIT\s+(\d+)/i);

    if (limitMatch) {
      const limitValue = limitMatch[1]; // Extracted number
      if (limitValue > 100000) {
        setLimitErrorMessage("Cant' Exceed more than 1 lakh");
        setIsLimitValid(false);
        // console.log(
        //   "can't go more than 1 lakh records try to break down in smaller chunk with the limit and offset value "
        // );
      } else {
        // console.log("Limit value:", limitValue); // Output: 10
        setLimitErrorMessage("");
        setIsLimitValid(true);
      }
    } else {
      // console.log("No LIMIT clause found");
      setLimitErrorMessage("");
      setIsLimitValid(true);
    }
  }, [formData.queryText, limitErrorMessage]);

  /////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    if (name === "addQuery" && !val) {
      setFormData({ ...formData, [name]: val, queryText: "" });
    } else {
      setFormData({ ...formData, [name]: val });
    }
  };

  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={"Done"}
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  // const for the data migration status role
  const [selectedTransferDept, setSelectedTransferDept] = useState("");

  // const { getRole } = useUserContext();

  const filteredOptions = transferDept.filter((option) => {
    if (viewRole === "DEV") return option.code === "qa";
    if (viewRole === "QA") return option.code === "prod";
    return true; // Show all options for admin or other roles
  });

  const accept = async () => {
    try {
      const axiosInstance = await myAxios();

      let resp = await axiosInstance
        .post(
          `/moveto${selectedTransferDept.name.toLowerCase()}?id=${ruleId}&move="moving"&approve="pending"`
        )
        .then((res) => res.data)
        .catch((e) => e.response.data);
      console.log(resp);
      // console.log(resp.status);
      toast.current.show({
        severity: "info",
        summary: "Confirmed",
        detail: `Job Migrate to ${selectedTransferDept.name}`,
        life: 3000,
      });
    } catch {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `Error while migrating to ${selectedTransferDept.name}`,
        life: 3000,
      });
    }
  };
  const reject = () => {
    setSelectedTransferDept("");
    setconfirmDialogVisible(false);
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "Request Cancalled",
      life: 3000,
    });
  };
  const [confirmDialogVisible, setconfirmDialogVisible] = useState(false);

  return (
    <>
      <Toast ref={toast} />
      <div style={{ marginLeft: "25px", marginBottom: "-25px" }}>
        <Breadcrumbs />
      </div>
      <div className="flex flex-column align-items-start justify-content-start">
        <form className="w-12 md:w-8 m-auto p-3" method="POST">
          <div className="grid">
            <div className="col-12">
              <div className="nameAndPush">
                <span className="text-2xl font-bold">
                  Database to Mount Transfer
                </span>
                <ConfirmDialog
                  group="declarative"
                  visible={confirmDialogVisible}
                  onHide={() => {
                    setSelectedTransferDept("");
                    setconfirmDialogVisible(false);
                  }}
                  message={`Are you sure you want to proceed with ${selectedTransferDept.name}?`}
                  header="Confirmation"
                  icon="pi pi-exclamation-triangle"
                  accept={accept}
                  reject={reject}
                />
                {viewRole !== "PROD" && (
                  <div>
                    <Dropdown
                      value={selectedTransferDept}
                      onChange={(e) => {
                        setSelectedTransferDept(e.value);
                        setconfirmDialogVisible(true);
                      }}
                      options={filteredOptions}
                      optionLabel="name"
                      placeholder="Migrate To:"
                      className="w-full migrateDrop"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 sm:col-10">
              <div className="flex-auto">
                <label
                  htmlFor="jobname"
                  className="font-semibold text-xs block mx-1 mb-1"
                >
                  Job Name
                </label>
                <InputText
                  id="jobname"
                  name="jobname"
                  className="w-full"
                  value={formData.jobname}
                  onChange={handleChange}
                  placeholder="Enter Job Name"
                  required
                />
              </div>
            </div>
            <div className="col-12 sm:col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Source</span>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select DataBase Details
                    </label>
                    <Dropdown
                      value={selectedDBOption || null}
                      onChange={handleDBSelectChange}
                      options={dbResponse || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Source Database"
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="tableName_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Table Name
                    </label>
                    <InputText
                      id="tableName_input"
                      name="tableName"
                      className="w-full"
                      value={formData.tableName}
                      onChange={handleChange}
                      placeholder="Enter Table Name"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="addQuery"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Add Query
                    </label>
                    <Checkbox
                      inputId="addQuery"
                      name="addQuery"
                      checked={formData.addQuery}
                      onChange={handleChange}
                    />
                  </div>

                  {formData.addQuery && (
                    <div className="group">
                      {limitErrorMessage && (
                        <p style={{ color: "red" }}>{limitErrorMessage}</p>
                      )}
                      <InputTextarea
                        name="queryText"
                        placeholder="Enter SQL query here"
                        className="w-full"
                        autoResize
                        value={formData.queryText}
                        onChange={handleChange}
                        style={{ marginTop: "10px" }}
                      />
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestForDBConnection(e, 1)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>

                {/* <div className="col-12"></div> */}
              </div>
            </div>

            <div className="col-2"></div>

            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Target</span>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select SFTP Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption || null}
                      onChange={handleSftpSelectChange}
                      options={sftpResponse || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Target SFTP"
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Path
                    </label>
                    <InputText
                      id="path_input"
                      name="filePath"
                      className="w-full"
                      value={formData.filePath}
                      onChange={handleChange}
                      placeholder="Enter Target Path"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="file_name_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      File Name
                    </label>
                    <InputText
                      id="file_name_input"
                      name="fileName"
                      className="w-full"
                      value={formData.fileName}
                      onChange={handleChange}
                      placeholder="Enter File Name"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Archive Path
                    </label>
                    <InputText
                      id="archiveLocation_input"
                      name="archiveLocation"
                      className="w-full"
                      value={formData.archiveLocation}
                      onChange={handleChange}
                      placeholder="Enter Archive Path"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 1)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
                <Dialog
                  header="Connection Test"
                  visible={visible}
                  style={{ width: "30vw" }}
                  onHide={() => setVisible(false)}
                  footer={footerContent}
                >
                  <Card>
                    {" "}
                    {loading ? (
                      <div className="card flex justify-content-center">
                        <ProgressSpinner
                          style={{ width: "50px", height: "50px" }}
                          strokeWidth="5"
                          fill="var(--surface-ground)"
                          animationDuration=".5s"
                        />
                      </div>
                    ) : (
                      <>
                        <h3 className="m-0 py-3">Status: {responseStatus}</h3>
                        <p className="m-0">Message: {responseMessage}</p>
                      </>
                    )}
                  </Card>
                </Dialog>
              </div>
            </div>

            <div className="col-12">
              <Button
                label="Schedule"
                type="submit"
                icon="pi pi-calendar"
                severity="primary"
                onClick={handleClick}
                className="border-round-lg"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
