export const apiConfig = {
  "/sftp-database": {
    default: "mtd/start",
    QA: "qa/mtd/start",
    PROD: "prod/mtd/start",
    successMessage: "Mount To Database Transfer Success",
  },
  "/sftp-transfer": {
    default: "mtm/start",
    QA: "qa/mtm/start",
    PROD: "prod/mtm/start",
    successMessage: "Mount To Mount Transfer Success",
  },
  "/database-sftp": {
    default: "dtm/start",
    QA: "qa/dtm/start",
    PROD: "prod/dtm/start",
    successMessage: "DB to Mount Transfer Success",
  },
  "/database-transfer": {
    default: "dtd/start",
    QA: "qa/dtd/start",
    PROD: "prod/dtd/start",
    successMessage: "DB to DB Transfer Task Added Successfully.",
  },
};
