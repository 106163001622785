// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-menuitem {
    position: relative;
  }
  
  .p-menuitem:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -5px; /* Adjust the position of the separator as needed */
    height: 60%;
    border-right: 1px solid #ccc; /* Customize the color and style of the separator */
    transform: translateY(-50%);
  }



`, "",{"version":3,"sources":["webpack://./src/DocumentReview/src/components/menu/menu.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,WAAW,EAAE,mDAAmD;IAChE,WAAW;IACX,4BAA4B,EAAE,mDAAmD;IACjF,2BAA2B;EAC7B","sourcesContent":[".p-menuitem {\r\n    position: relative;\r\n  }\r\n  \r\n  .p-menuitem:not(:last-child)::after {\r\n    content: '';\r\n    position: absolute;\r\n    top: 50%;\r\n    right: -5px; /* Adjust the position of the separator as needed */\r\n    height: 60%;\r\n    border-right: 1px solid #ccc; /* Customize the color and style of the separator */\r\n    transform: translateY(-50%);\r\n  }\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
