import React, { useState, useEffect, useRef, Fragment } from "react";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DataService } from "../service/data";
import { useDispatch, useSelector } from "react-redux";
import dfSlice, { dfActions } from "../../store/df-slice";
import { columnActions } from "../../store/column-slice";
import { InputText } from "primereact/inputtext";
import { ToggleButton } from "primereact/togglebutton";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import "./table.css";
import { Dropdown } from "primereact/dropdown";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

export default function LazyLoadDemo({ filename }) {
  // console.log("The file name is" + filename);
  const columns = useSelector((state) => state.column.columns);
  const dyanmicData = useSelector((state) => state.df.data);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const dispatch = useDispatch();
  const [balanceFrozen, setBalanceFrozen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 100,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: null,
    fileName: null,
  });

  useEffect(() => {
    if (columns && columns !== null && columns !== undefined) {
      setlazyState((prev) => ({
        ...prev,
        filters: columns.reduce((obj, key) => {
          obj[key] = { value: "", matchMode: "contains" };
          return obj;
        }, {}),
      }));
    }
  }, [columns]);

  const auth = useSelector((state) => state.auth.login);
  const profile = auth ? auth.id : "profile";
  // console.log('filename....',(filename))
  // console.log('profile....',(profile))
  useEffect(() => {
    setlazyState((prev) => ({
      ...prev,
      fileName: filename,
      user: profile,
    }));
  }, [filename]);

  let networkTimeout = null;

  useEffect(() => {
    loadLazyData(null);
  }, [lazyState]);

  useEffect(() => {
    loadLazyData(keyword);
  }, [keyword]);

  // const loadLazyData = (keyword) => {
  //   setLoading(true);
  //   // console.log("calling loadLazyData");
  //   if (networkTimeout) {
  //     clearTimeout(networkTimeout);
  //   }

  //   let tableparam = lazyState;
  //   if (keyword != null) {
  //     tableparam.keyword = keyword;
  //     // console.log(tableparam);
  //   } else {
  //     tableparam.keyword = null;
  //   }
  //   networkTimeout = setTimeout(async () => {
  //     //   console.log(lazyState);
  //     //   console.log(lazyState);

  //     const dataService = await DataService();
  //     dataService
  //       .getData({ lazyEvent: JSON.stringify(lazyState) })
  //       .then((data) => {
  //         setTotalRecords(data.totalRecords);
  //         setLoading(false);
  //         const dataColumns = columnActions.setColumns(
  //           Object.keys(JSON.parse(data.results)[0])
  //         );
  //         // console.log(dataColumns);
  //         // console.log(dataColumns.payload);
  //         // console.log("columns");
  //         // console.log(columns);
  //         if (JSON.stringify(dataColumns.payload) !== JSON.stringify(columns)) {
  //           dispatch(dataColumns);
  //         }
  //         dispatch(dfActions.setData(JSON.parse(data.results)));
  //       });
  //   }, Math.random() * 1000 + 250);
  // };

  const loadLazyData = (keyword) => {
    setLoading(true);
    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    let tableparam = lazyState;
    tableparam.keyword = keyword || null;

    networkTimeout = setTimeout(async () => {
      const dataService = await DataService();
      try {
        const data = await dataService.getData({
          lazyEvent: JSON.stringify(lazyState),
        });
        setTotalRecords(data.totalRecords);
        setLoading(false);

        // Log the results to ensure valid structure
        // console.log("data.results:", data.results);

        const parsedResults = JSON.parse(data.results);
        if (parsedResults.length > 0) {
          const dataColumns = columnActions.setColumns(
            Object.keys(parsedResults[0])
          );
          // console.log("dataColumns:", dataColumns);

          // Check for payload existence
          if (dataColumns && dataColumns.payload) {
            if (
              JSON.stringify(dataColumns.payload) !== JSON.stringify(columns)
            ) {
              dispatch(dataColumns);
            }
          } else {
            console.warn("dataColumns.payload is undefined:", dataColumns);
          }

          dispatch(dfActions.setData(parsedResults));
        } else {
          console.warn("Parsed results is empty:", parsedResults);
        }
      } catch (error) {
        console.error("Error loading data:", error);
        setLoading(false);
      }
    }, Math.random() * 1000 + 250);
  };

  const onPage = (event) => {
    debugger;
    event["fileName"] = filename;
    event["page"] = event["page"] + 1;
    event["user"] = profile;
    setlazyState(event);
  };

  const onSort = (event) => {
    event["fileName"] = filename;
    event["user"] = profile;
    setlazyState(event);
  };

  const onFilter = (event) => {
    event["first"] = 0;
    event["fileName"] = filename;
    event["user"] = profile;
    setlazyState(event);
  };

  const onSelectionChange = (event) => {
    const value = event.value;

    setSelectedData(value);
    setSelectAll(value.length === totalRecords);
  };

  const onSelectAllChange = async (event) => {
    const selectAll = event.checked;
    const dataService = await DataService();
    if (selectAll) {
      dataService.getData().then((data) => {
        setSelectAll(true);
        setSelectedData(data.customers);
      });
    } else {
      setSelectAll(false);
      setSelectedData([]);
    }
  };

  const handleInputChange = (event) => {
    let keyword = event.target.value;
    // console.log(keyword);
    if (keyword == "") {
      keyword = null;
    }
    setKeyword(keyword);
  };

  const toast = useRef(null);
  const handleSaveButtonClick = async () => {
    const requestData = {
      filename: filename,
      editedData: editedData,
    };

    const dataService = await DataService(); // Ensure this is awaited
    // console.log(dataService); // Check what is returned

    try {
      await dataService.sendEditedDataToBackend(requestData); // Remove JSON.stringify
      toast.current.show({
        severity: "info",
        summary: "Success",
        detail: "File Saved Successfully",
      });
    } catch (error) {
      console.error("Error saving data:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to save file",
      });
    }
  };

  const columnNames = columns ? columns.map((col) => col) : [];
  const initialRowData = Array(columnNames.length).fill(null);

  const [displayDialog, setDisplayDialog] = useState(false);
  const [rowData, setRowData] = useState(Array(columnNames.length).fill(null));

  const handleAddButtonClick = () => {
    setDisplayDialog(true);
    setRowData(initialRowData);
  };

  const onHideDialog = () => {
    setDisplayDialog(false);
  };

  const handleColumnChange = (index, value) => {
    const updatedRowData = [...rowData];
    // updatedRowData[index] = value;
    updatedRowData[index] = value === "" ? null : value;
    setRowData(updatedRowData);
  };

  const handleSubmit = async () => {
    if (rowData.length === 0 || rowData.every((value) => !value)) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please enter at least one column",
      });
      return;
    }
    // console.log("Row Data:", rowData);
    toast.current.show({
      severity: "info",
      summary: "Sucess",
      detail: "Added Succesfully",
    });
    setDisplayDialog(false);
    const requestData = {
      rowData: rowData,
      filename: filename,
    };
    const dataService = await DataService();
    dataService.sendRowDataToBackend(requestData).then((data) => {
      dispatch(dfActions.setData(JSON.parse(data)));
      // console.log("The new row sent to backend:", data);
    });
  };

  const midpoint = Math.ceil(columnNames.length / 2);
  const firstHalf = columnNames.slice(0, midpoint);
  const secondHalf = columnNames.slice(midpoint);
  const InsertNewRow = (
    <div className="flex-auto mt-2 grid grid-cols-2 gap-4">
      <div>
        {firstHalf.map((columnName, index) => (
          <div key={index}>
            <label className="flex-auto font-bold ml-3 ">{columnName}</label>
            <div className="input-container">
              <InputText
                className="box mt-2 mb-4 ml-2 "
                value={rowData[index] || ""}
                onChange={(e) => handleColumnChange(index, e.target.value)}
              />
            </div>
          </div>
        ))}
      </div>
      <div>
        {secondHalf.map((columnName, index) => (
          <div key={index} className="col-50">
            <label className="flex-auto font-bold ml-3 ">{columnName}</label>
            <div className="input-container-1">
              <InputText
                className="box mt-2 mb-4 ml-2"
                value={rowData[index + midpoint] || ""}
                onChange={(e) =>
                  handleColumnChange(index + midpoint, e.target.value)
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  // Here we are starting the replace funtion logics
  const fileName = useSelector((state) => state.table.name);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [findWhat, setFindWhat] = useState("");
  const [replaceWith, setReplaceWith] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");

  const handleReplaceButtonClick = () => {
    setIsDialogVisible(true);
  };

  const onHide = () => {
    setIsDialogVisible(false);
    setIsChecked(false);
  };

  const handleSubmit1 = async () => {
    if (!findWhat || !replaceWith || !selectedColumn) {
      toast.current.show({
        severity: "error",
        summary: "Null Error",
        detail: "No words Replaced",
      });
    } else {
      toast.current.show({
        severity: "info",
        summary: "Success",
        detail: "Replaced Successfully",
      });
    }
    setFindWhat("");
    setReplaceWith("");
    setSelectedColumn("");
    setIsDialogVisible(false);
    setSelectedCategories([]);
    setIsChecked(false);
    setInputNumber();
    // console.log('Selected Index:',inputNumber);
    // console.log('Find What:', findWhat);
    // console.log('Replace With:', replaceWith);
    const requestData = {
      selectedColumn: selectedColumn,
      findWhat: findWhat,
      replaceWith: replaceWith,
      filename: fileName,
      selectedCategories: selectedCategories,
      inputNumber: inputNumber,
      searchmode: selectedCategories2,
    };
    // console.log(requestData)
    // console.log('aaaaaaaaaaaaaaaaaa',selectedCategories)
    const dataService = await DataService();
    dataService.sendReplaceToBackend(requestData).then((data) => {
      dispatch(dfActions.setData(JSON.parse(data)));
    });
  };

  const handleColumnSelect = (event) => {
    setSelectedColumn(event.target.value);
  };

  const columnNameDropdown = (
    <Dropdown
      className="w-full md:w-20rem mb-5"
      placeholder="Select a column"
      filter
      options={columnNames}
      value={selectedColumn}
      onChange={handleColumnSelect}
    />
  );

  //here starts the additional features

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategories1, setSelectedCategories1] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [inputNumber, setInputNumber] = useState(0);

  const categories = [
    { name: "Match Whole Word Only", key: "O" },
    { name: "Match Case", key: "M" },
  ];

  const categories1 = [{ name: "Wrap Around", key: "W" }];

  const categories2 = [
    { name: "Normal", key: "N" },
    { name: "Extended (\\n,\\r,\\t,\\0,\\x...)", key: "E" },
  ];
  const [selectedCategories2, setSelectedCategories2] = useState(
    categories2[0]
  );

  const onCategoryChange = (e) => {
    let _selectedCategories = [...selectedCategories];
    if (e.checked) _selectedCategories.push(e.value);
    else
      _selectedCategories = _selectedCategories.filter(
        (category) => category.key !== e.value.key
      );
    setSelectedCategories(_selectedCategories);
  };

  const onCategoryChange1 = () => {
    setIsChecked((prevValue) => !prevValue);
  };

  const handleInputNumber = (e) => {
    setInputNumber(e.value);
  };

  const onCategoryChange2 = (e) => {
    setSelectedCategories2(e.value);
  };

  const additionalReplace = (
    <div>
      <div className="flex flex-column gap-3 mt-4 ml-3">
        {categories.map((category) => {
          return (
            <div key={category.key} className="flex align-items-center">
              <Checkbox
                inputId={category.key}
                name="category"
                value={category}
                onChange={onCategoryChange}
                checked={selectedCategories.some(
                  (item) => item.key === category.key
                )}
              />
              <label htmlFor={category.key} className="ml-2">
                {category.name}
              </label>
            </div>
          );
        })}
      </div>
      <div className="flex flex-column gap-3 mt-3 ml-3">
        {categories1.map((category1) => {
          return (
            <div key={category1.key} className="flex align-items-center">
              <Checkbox
                inputId={category1.key}
                name="category"
                value={category1}
                onChange={onCategoryChange1}
                checked={selectedCategories1.some(
                  (item) => item.key === category1.key
                )}
              />
              <label htmlFor={category1.key} className="ml-2">
                {category1.name}
              </label>
              <div className="ml-3">
                {isChecked && (
                  <div className="input-number">
                    <label>Select the Index</label>
                    <InputNumber
                      value={inputNumber}
                      onChange={handleInputNumber}
                    ></InputNumber>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="card flex justify-content-left mt-4"
        style={{ width: "300px" }}
      >
        <div className="flex flex-column gap-3 my-3 ml-3">
          {categories2.map((category2) => {
            return (
              <div key={category2.key} className="flex align-items-center">
                <RadioButton
                  inputId={category2.key}
                  name="category2"
                  value={category2}
                  onChange={onCategoryChange2}
                  checked={selectedCategories2.key === category2.key}
                />
                <label htmlFor={category2.key} className="ml-2">
                  {category2.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
  //here ends the logics of replace button

  const GlobalFilter = (
    <>
      <div className="flex justify-content-start "></div>
      <ToggleButton
        style={{ marginBottom: "0px" }}
        checked={balanceFrozen}
        onChange={(e) => setBalanceFrozen(e.value)}
        onIcon="pi pi-lock"
        offIcon="pi pi-lock-open"
        onLabel="Edit"
        offLabel="Edit"
      />
      <Toast ref={toast} />
      <Button
        className="ml-2"
        label="Save"
        icon={PrimeIcons.SAVE}
        onClick={handleSaveButtonClick}
      />
      <Button
        className="ml-2"
        label="Add"
        icon={PrimeIcons.PLUS}
        onClick={handleAddButtonClick}
      />
      <Button
        className="ml-2"
        label="Replace"
        icon="pi pi-arrow-right-arrow-left"
        onClick={handleReplaceButtonClick}
      />{" "}
      {/* This button is used for replace option */}
      <Dialog
        style={{ minWidth: "80%" }}
        className="flex justify-content-center"
        header="Add New Row"
        visible={displayDialog}
        onHide={onHideDialog}
      >
        {InsertNewRow}
        <div className="mt-6 flex justify-content-end">
          <Button label="Submit" onClick={handleSubmit} />
        </div>
      </Dialog>
      <div />
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={keyword}
            onChange={handleInputChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
      {/* here is replace button function */}
      <Fragment>
        <Dialog
          style={{ minWidth: "50%" }}
          className="flex justify-content-center"
          header="Find & Replace"
          visible={isDialogVisible}
          onHide={onHide}
        >
          <label className="font-bold ml-3 mr-2 ">Select a Column :</label>
          {columnNameDropdown}

          <div className="input-container">
            <label className="font-bold ml-3 ">Find What :</label>
            <InputText
              className="ml-2"
              value={findWhat}
              onChange={(e) => setFindWhat(e.target.value)}
              style={{ width: "300px", height: "40px" }}
            />
            <label className="font-bold ml-3 ">Replace With :</label>
            <InputText
              className="ml-2"
              value={replaceWith}
              onChange={(e) => setReplaceWith(e.target.value)}
              style={{ width: "300px", height: "40px" }}
            />
          </div>

          <div>
            {/* This is the additional features is implimented here */}
            {additionalReplace}
          </div>
          <div className="mt-6 flex justify-content-end">
            <Button label="Submit" onClick={handleSubmit1} />
          </div>
        </Dialog>
      </Fragment>
    </>
  );

  const [editedData, setEditedData] = useState([]);
  const onRowEditComplete = (event) => {
    const { newData, index } = event;
    // console.log(index);
    const tempData = dyanmicData.map((item, dataIndex) => {
      // console.log(dataIndex, index);
      if (dataIndex === index) {
        return { ...item, ...newData };
      }
      return item;
    });
    dispatch(dfActions.setData(tempData));
    let edit = {
      Index: index,
      Data: newData,
    };
    let eData = [...editedData];
    eData.push(edit);
    setEditedData(eData);
  };

  const textEditor = (dyanmicData) => {
    return (
      <InputText
        type="text"
        value={dyanmicData.value}
        onChange={(e) => dyanmicData.editorCallback(e.target.value)}
      />
    );
  };

  const onDelete = async (i) => {
    const newData = [...dyanmicData];
    newData.splice(i.rowIndex, 1);
    dispatch(dfActions.setData(newData));
    const requestData = {
      rowIndex: i.rowIndex,
      filename: filename,
    };
    const dataService = await DataService();
    dataService.sendRowIndexToBackend(requestData).then((data) => {
      // console.log("The Index sent to backend:", data);
    });
  };

  const rowDelete = (Index) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          square
          outlined
          severity="danger"
          onClick={() => onDelete(Index)}
        />
      </React.Fragment>
    );
  };

  return (
    <div className="card">
      <DataTable
        header={GlobalFilter}
        value={dyanmicData}
        editMode="row"
        onRowEditComplete={onRowEditComplete}
        lazy
        filterDisplay="row"
        dataKey="id"
        paginator
        scrollable
        scrollHeight="1000px"
        first={lazyState.first}
        rows={1000}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        onFilter={onFilter}
        filters={lazyState.filters}
        loading={loading}
        tableStyle={{ minWidth: "75rem" }}
        selection={selectedData}
        onSelectionChange={onSelectionChange}
        selectAll={selectAll}
        onSelectAllChange={onSelectAllChange}
      >
        {columns
          ? columns.map((col) => (
              <Column
                field={col}
                header={col}
                footer={col}
                style={{ minWidth: "100px" }}
                sortable
                filter
                filterPlaceholder="Search"
                editor={(props) =>
                  textEditor({ ...props, value: props.rowData[col] || "" })
                }
              ></Column>
            ))
          : null}
        <Column
          rowEditor
          headerStyle={{ width: "10%", minWidth: "1rem" }}
          bodyStyle={{ textAlign: "center" }}
          alignFrozen="right"
          frozen={balanceFrozen}
        ></Column>
        <Column
          body={(rowData, rowIndex) => rowDelete(rowIndex)}
          headerStyle={{ width: "10%", minWidth: "1rem" }}
          bodyStyle={{ textAlign: "center" }}
          alignFrozen="right"
          frozen={balanceFrozen}
        ></Column>
      </DataTable>
    </div>
  );
}
