// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-button.p-button-outlined.custom-file {
    color: #0000ff;
    border: 2px solid;
}

/* .p-button.p-button-outlined.custom-file:hover {
    background-color: transparent;
    color: #000000;
} */

.p-button.p-button-success.p-button-outlined.custom-uploader {
    color: #008d07;
    border: 2px solid;
}

.p-button.p-button-danger.p-button-outlined.custom-cancel {
    color: #dc0000;
    border: 2px solid;
}

.p-tag.custom-tag {
    background: #6366F1;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/DocumentReview/src/components/upload/upload.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;;;GAGG;;AAEH;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;AACtB","sourcesContent":[".p-button.p-button-outlined.custom-file {\r\n    color: #0000ff;\r\n    border: 2px solid;\r\n}\r\n\r\n/* .p-button.p-button-outlined.custom-file:hover {\r\n    background-color: transparent;\r\n    color: #000000;\r\n} */\r\n\r\n.p-button.p-button-success.p-button-outlined.custom-uploader {\r\n    color: #008d07;\r\n    border: 2px solid;\r\n}\r\n\r\n.p-button.p-button-danger.p-button-outlined.custom-cancel {\r\n    color: #dc0000;\r\n    border: 2px solid;\r\n}\r\n\r\n.p-tag.custom-tag {\r\n    background: #6366F1;\r\n    color: #ffffff;\r\n    font-size: 1rem;\r\n    font-weight: 700;\r\n    padding: 0.25rem 0.4rem;\r\n    border-radius: 6px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
