import loadConfig from "../../../../config";

export const Table = async () => {
  const config = await loadConfig();

  const getDataframe = (data) => {
    return fetch(`${config.pythonBaseURL}/user/table/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    }).then((res) => res.json());
  };
  return getDataframe;
};
