import React, { useState, useContext, useEffect, useRef } from "react";
import "../css/rule.css";
import { ContextTimeScheduling } from "../../components/scheduleComponent/ContextTimeScheduling";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import Breadcrumbs from "../../components/Breadcrumb";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { viewRolesmtm, transferDept } from "./viewRoleApis";

export default function EditMountRules() {
  const navigate = useNavigate();
  const [ruleData, setRuleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { ruleId, viewRole } = location.state || {};
  const [checked, setChecked] = useState(false);
  const toast = React.useRef(null);

  ///Here state for test connection

  const [visible, setVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  //This state belongs to SFTP selection dropdwon
  const [selectedSftpOption, setSelectedSftpOption] = useState("");
  const [selectedSftpOption2, setSelectedSftpOption2] = useState("");
  const { response } = location.state || [];

  // console.log("Hello", response);
  // response.map((res) => console.log(res.name));
  useEffect(() => {
    // console.log(selectedViewAs+"this is the selected view as")
    if (ruleId === undefined) navigate("/home");
    setSelectedViewAs(viewRole);
    const fetchData = async () => {
      try {
        const axiosInstance = await myAxios();
        if (viewRole === "DEV") {
          const response = await axiosInstance.get(
            `${viewRolesmtm[0].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        } else if (viewRole === "QA") {
          const response = await axiosInstance.get(
            `${viewRolesmtm[1].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        } else if (viewRole === "PROD") {
          const response = await axiosInstance.get(
            `${viewRolesmtm[2].fetchApi(ruleId)}`
          );
          setRuleData(response.data);
        }
      } catch (error) {
        console.log(error);
        console.log(error.response);
        let errorValue = error.response ? error.response : null;
        console.log(errorValue);
        if (errorValue == null) {
          navigate("/login");
        } else if (errorValue.status === 401) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);

          toast.current.show({
            severity: "error",
            summary: "Token Expired ",
            detail: "Session Time Out ",
            life: 2000,
          });
        } else if (errorValue.status === 403) {
          setTimeout(() => {
            navigate("/login");
          }, 2000);

          toast.current.show({
            severity: "error",
            summary: "Access Denied",
            detail: "Try Login Again.",
            life: 2000,
          });
        }

        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (ruleId) {
      fetchData();
    }
  }, [ruleId]);

  const { setOpenDialog, setcontextFormData, setSelectedViewAs } = useContext(
    ContextTimeScheduling
  );
  const [formData, setFormData] = useState({
    sourceSecretName: "",
    server1: "",
    port1: "",
    sftpUser1: "",
    sftpPass1: "",
    file1path: "",
    fileName: "",
    jobname: "",
    isfolder: false,
    targetSecretName: "",
    server2: "",
    port2: "",
    sftpUser2: "",
    sftpPass2: "",
    file2path: "",
    archiveLocation: "",
    // seconds: "",
    minutes: "",
    hours: "",
    months: "",
    days: "",
    weeks: "",
    frequencyType: "",
    // month: "",
    timeZone: "",
    startDate: "",
    startTime: "",
    endDate: "",
    ruleId: "",
    jiraTicketNumber: "",
    version: "",
  });

  //Drop down handle change for the source
  // const handleSftpSelectChange = (event) => {
  //   const selectedName = event.target.value;
  //   setSelectedSftpOption(selectedName);

  //   const selectedData = response.find(
  //     (item) => item.name === selectedName.name
  //   );
  //   if (selectedData) {
  //           const environmentData =
  //             viewRole === "QA" ? selectedData["qa"] ?viewRole === "PROD" ? selectedData["prod"]  ?viewRole === "DEV" ? selectedData["dev"] ;
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       sourceSecretName: selectedName.name || "",
  //       server1: environmentData?.host || "",
  //       port1: environmentData?.port || "",
  //       sftpUser1:
  //         environmentData?.username || "",
  //       sftpPass1:
  //         environmentData?.password || "",
  //     }));
  //   }
  // };

  const handleSftpSelectChange = (event) => {
    const selectedName = event.target.value;
    setSelectedSftpOption(selectedName);

    const selectedData = response.find(
      (item) => item.name === selectedName.name
    );

    if (selectedData) {
      let environmentData;

      // Check the viewRole and select corresponding environment data
      if (viewRole === "QA") {
        environmentData = selectedData["qa"];
      } else if (viewRole === "PROD") {
        environmentData = selectedData["prod"];
      } else if (viewRole === "DEV") {
        environmentData = selectedData["dev"];
      }

      // Set form data with the selected environment data
      setFormData((prevState) => ({
        ...prevState,
        sourceSecretName: selectedName.name || "",
        server1: environmentData?.host || "",
        port1: environmentData?.port || "",
        sftpUser1: environmentData?.username || "",
        sftpPass1: environmentData?.password || "",
      }));
    }
  };

  // console.log(formData);

  //Drop down handle change for the target
  const handleSftpSelectChange2 = (event) => {
    const selectedName2 = event.target.value;
    setSelectedSftpOption2(selectedName2);

    const selectedData2 = response.find(
      (item) => item.name === selectedName2.name
    );
    if (selectedData2) {
      let environmentData;

      // Check the viewRole and select corresponding environment data
      if (viewRole === "QA") {
        environmentData = selectedData2["qa"];
      } else if (viewRole === "PROD") {
        environmentData = selectedData2["prod"];
      } else if (viewRole === "DEV") {
        environmentData = selectedData2["dev"];
      }
      setFormData((prevState) => ({
        ...prevState,
        targetSecretName: selectedName2.name || "",
        server2: environmentData?.host || "",
        port2: environmentData?.port || "",
        sftpUser2: environmentData?.username || "",
        sftpPass2: environmentData?.password || "",
      }));
    }
  };

  //////////////////////////////////
  // this function is used to set the data .
  // setSelectedSftpOption(selectedName);
  const setServerData = (secretName) => {
    response.map((resp) => {
      if (resp.name === secretName) {
        setSelectedSftpOption(resp);
        // console.log(resp)
      }
    });
  };

  const setServerData2 = (secretName2) => {
    response.map((resp) => {
      if (resp.name === secretName2) {
        setSelectedSftpOption2(resp);
        // console.log(resp)
      }
    });
  };

  //////////////////////////////////

  const footerContent = (
    <div>
      <Button
        type="submit"
        severity="primary"
        label={"Done"}
        icon="pi pi-check"
        onClick={(e) => {
          e.preventDefault();
          setVisible(false);
        }}
        autoFocus
      />
    </div>
  );

  /////////////////////////////////////////////////////
  // added for test connection for sftp1

  const [sftpServer1, setSftpServer1] = useState({
    server1: "",
    port1: "",
    user1: "",
    pass1: "",
    filePath: "",
  });

  //useEffect on outside
  useEffect(() => {
    if (sftpServer1) {
      // console.log(sftpServer1);
      if (checkSftpValid()) {
        setLoading(true);
        setVisible(true);
        callTestSFTP();
      }
    }
  }, [sftpServer1]);

  const checkSftpValid = () => {
    for (const key in sftpServer1) {
      if (sftpServer1[key] === "") {
        return false; // Return false if any field is empty
      }
    }
    return true;
  };

  ////////////////////////////////////

  useEffect(() => {
    // console.log(checked, formData.fileName);
    if (checked) {
      formData.fileName = "";
      formData.isfolder = true;
    } else {
      formData.isfolder = false;
    }
    // console.log(checked, formData.fileName, formData.isfolder);
  }, [checked]);

  const callTestSFTP = async () => {
    try {
      const axiosInstance = await myAxios();
      let sftp_connect = await axiosInstance
        .post("/test/con/sftp", sftpServer1)
        .then((res) => res.data);

      // console.log(sftp_connect);

      let status = sftp_connect.status;
      let message = sftp_connect.messages;

      setResponseMessage(message);
      setResponseStatus(status);
    } catch (e) {
      console.log(e);
      let errorResponse = e.response ? e.response : null;
      if (errorResponse == null) {
        setResponseMessage("Server Link Failed");
        setResponseStatus("500");

        setTimeout(() => {
          localStorage.clear();
          navigate("/login");
        }, 2000);
        toast.current.show({
          severity: "error",
          summary: "Network Error",
          detail: "Server Link Failure",
          life: 2000,
        });

        return;
      } else if (errorResponse.status === 401) {
        setResponseMessage("Session Expired ");
        setResponseStatus("401");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        toast.current.show({
          severity: "error",
          summary: "Session Timeout",
          detail: "Session Expired!, Try Login Again.",
          life: 2000,
        });
        return;
      } else {
        setResponseMessage(errorResponse.data.messages || "Failed to connect.");
        setResponseStatus(errorResponse.data.status || "Error");
      }
    } finally {
      setLoading(false);
    }
  };

  // handle test connection
  const handleTestConnection = async (e, buttonType) => {
    e.preventDefault();

    if (buttonType === 1) {
      if (!checked) {
        if (formData.fileName.trim() === "") {
          toast.current.show({
            severity: "error",
            summary: "File Name",
            detail: "Please Provide the fileName.",
            life: 3000,
          });
          return;
        }
      }

      if (
        formData.file1path.trim() === "" ||
        // formData.fileName.trim() === "" ||
        formData.server1.trim() === "" ||
        formData.port1.trim() === "" ||
        formData.sftpUser1.trim() === "" ||
        formData.sftpPass1.trim() === ""
      ) {
        // console.log(checkSftpValid(), " sftp details ");
        // console.log(sftpServer1);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill the Source's required fields .",
          life: 3000,
        });
        return;
      } else {
        setSftpServer1({
          server1: formData.server1,
          port1: formData.port1,
          user1: formData.sftpUser1,
          pass1: formData.sftpPass1,
          filePath: formData.file1path + "/" + formData.fileName,
        });
      }
    } else {
      if (
        formData.file2path.trim() === "" ||
        formData.server2.trim() === "" ||
        formData.port2.trim() === "" ||
        formData.sftpUser2.trim() === "" ||
        formData.sftpPass2.trim() === ""
      ) {
        // console.log(checkSftpValid(), " sftp details ");
        // console.log(sftpServer1);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please fill the Target's required fields .",
          life: 3000,
        });
        return;
      } else {
        setSftpServer1({
          server1: formData.server2,
          port1: formData.port2,
          user1: formData.sftpUser2,
          pass1: formData.sftpPass2,
          filePath: formData.file2path,
        });
      }
    }
  };

  ///////////////////////////////////////////////////////

  useEffect(() => {
    if (ruleData) {
      // const cronValues = parseCronExpression(ruleData.cronExpression);
      // set the check value .
      setChecked(ruleData.isfolder);
      // console.log(ruleData, "Ruledata");

      // Extract time part from startDate
      setServerData(ruleData.sourceSecretName);
      setServerData2(ruleData.targetSecretName);

      const startTime = ruleData.startDate
        ? ruleData.startDate.split("T")[1]
        : "";
      setFormData({
        sourceSecretName: ruleData.sourceSecretName || "",
        server1: ruleData.sftpHost1 || "",
        port1: ruleData.port1.toString() || "",
        sftpUser1: ruleData.sftpUser1 || "",
        sftpPass1: ruleData.password1 || "",
        file1path: ruleData.sourceLocation || "",
        jobname: ruleData.jobname || "",
        fileName: ruleData.fileName || "",
        targetSecretName: ruleData.targetSecretName || "",
        server2: ruleData.sftpHost2 || "",
        port2: ruleData.port2.toString() || "",
        isfolder: ruleData.isfolder || false,
        sftpUser2: ruleData.sftpUser2 || "",
        sftpPass2: ruleData.password2 || "",
        file2path: ruleData.targetLocation || "",
        archiveLocation: ruleData.archiveLocation || "",
        months: ruleData.months || "",
        hours: ruleData.hours || "",
        minutes: ruleData.minutes || "",
        weeks: ruleData.weeks || "",
        days: ruleData.days || "",
        frequencyType: ruleData.frequencyType || "",
        // ...cronValues,
        timeZone: ruleData.timeZone || "",
        startDate: ruleData.startDate || "",
        startTime: startTime || "", // Set startTime here
        endDate: ruleData.endDate || "",
        ruleId: ruleData.id || "",
        jiraTicketNumber: ruleData.jiraTicketNumber || "",
        version: ruleData.version || "",
      });
    }
  }, [ruleData]);

  // console.log(formData, "Kuna");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  ///////////////////////////////////////////////////////////

  const validateForm = () => {
    // console.log(formData, " validate form ");
    if (!checked) {
      if (formData.fileName === "") {
        return false;
      }
    }

    const listofValidateFields = [
      "jobname",
      "sourceSecretName",
      "targetSecretName",
      "file1path",
      "file2path",
      "fileName",
      "archiveLocation",
    ];

    for (let e of listofValidateFields) {
      if (formData[e] === "") {
        return false;
      }
    }

    return true;
  };

  // need to update here for the fileName;
  const handleClick = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setcontextFormData(formData);
      setOpenDialog(true); // This should trigger the dialog to open
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please fill in all fields.",
        life: 3000,
      });
    }
  };

  ////////////////////////////////////////////////////////////

  // const for the data migration status role
  const [selectedTransferDept, setSelectedTransferDept] = useState("");

  // const { getRole } = useUserContext();

  const filteredOptions = transferDept.filter((option) => {
    if (viewRole === "DEV") return option.code === "qa";
    if (viewRole === "QA") return option.code === "prod";
    return true; // Show all options for admin or other roles
  });

  const accept = async () => {
    try {
      const axiosInstance = await myAxios();

      let resp = await axiosInstance
        .post(
          `/moveto${selectedTransferDept.name.toLowerCase()}?id=${ruleId}&move="moving"&approve="pending"`
        )
        .then((res) => res.data)
        .catch((e) => e.response.data);
      console.log(resp);
      // console.log(resp.status);
      toast.current.show({
        severity: "info",
        summary: "Confirmed",
        detail: `Job Migrate to ${selectedTransferDept.name}`,
        life: 3000,
      });
    } catch {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `Error while migrating to ${selectedTransferDept.name}`,
        life: 3000,
      });
    }
  };
  const reject = () => {
    setSelectedTransferDept("");
    setconfirmDialogVisible(false);
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "Request Cancalled",
      life: 3000,
    });
  };
  const [confirmDialogVisible, setconfirmDialogVisible] = useState(false);

  return (
    <>
      <Toast ref={toast} />

      <div style={{ marginLeft: "25px", marginBottom: "-25px" }}>
        <Breadcrumbs />
      </div>
      <div className="flex flex-column align-items-start justify-content-start">
        <form className="w-12 md:w-8 m-auto p-3" method="POST">
          <div className="grid">
            <div className="col-12">
              <div className="nameAndPush">
                <span className="text-2xl font-bold">
                  Mount to Mount Transfer
                </span>
                <ConfirmDialog
                  group="declarative"
                  visible={confirmDialogVisible}
                  onHide={() => {
                    setSelectedTransferDept("");
                    setconfirmDialogVisible(false);
                  }}
                  message={`Are you sure you want to proceed with ${selectedTransferDept.name}?`}
                  header="Confirmation"
                  icon="pi pi-exclamation-triangle"
                  accept={accept}
                  reject={reject}
                />
                {viewRole !== "PROD" && (
                  <div>
                    <Dropdown
                      value={selectedTransferDept}
                      onChange={(e) => {
                        setSelectedTransferDept(e.value);
                        setconfirmDialogVisible(true);
                      }}
                      options={filteredOptions}
                      optionLabel="name"
                      placeholder="Migrate To:"
                      className="w-full migrateDrop"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 sm:col-10">
              <div className="flex-auto">
                <label
                  htmlFor="jobname"
                  className="font-semibold text-xs block mx-1 mb-1"
                >
                  Job Name
                </label>
                <InputText
                  id="jobname"
                  name="jobname"
                  className="w-full"
                  value={formData.jobname}
                  onChange={handleChange}
                  placeholder="Enter Job Name"
                  required
                />
              </div>
            </div>{" "}
            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Source</span>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select SFTP Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption || null}
                      onChange={handleSftpSelectChange}
                      options={response || []}
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Source SFTP"
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Path
                    </label>
                    <InputText
                      id="path_input"
                      name="file1path"
                      className="w-full"
                      value={formData.file1path}
                      onChange={handleChange}
                      placeholder="Enter Source Path"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="isfolder"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      All Files in the Folder
                    </label>
                    <Checkbox
                      inputId="isfolder"
                      name="isfolder"
                      value={formData.isfolder}
                      onChange={(e) => setChecked(e.checked)}
                      // onChange={handleChange}
                      checked={checked}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="file_name_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      File Name
                    </label>
                    <InputText
                      id="file_name_input"
                      name="fileName"
                      className="w-full"
                      value={formData.fileName}
                      onChange={handleChange}
                      placeholder="Enter File Name"
                      disabled={checked}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="archiveLocation_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Archive Path
                    </label>
                    <InputText
                      id="archiveLocation_input"
                      name="archiveLocation"
                      className="w-full"
                      value={formData.archiveLocation}
                      onChange={handleChange}
                      placeholder="Enter Archive Path"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 1)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
            <div className="col-4">
              <div className="grid w-full">
                <div className="col-12">
                  <span className="text-lg font-bold uppercase">Target</span>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="dns_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Select SFTP Details
                    </label>
                    <Dropdown
                      value={selectedSftpOption2 || null} // Ensuring selectedSftpOption is properly initialized
                      onChange={handleSftpSelectChange2}
                      options={response || []} // Default to an empty array if response is null or undefined
                      optionLabel="name"
                      // showClear
                      placeholder="Select a Target SFTP"
                      className="w-full"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <label
                      htmlFor="path2_input"
                      className="font-semibold text-xs block mx-1 mb-1"
                    >
                      Path
                    </label>
                    <InputText
                      id="path2_input"
                      name="file2path"
                      className="w-full"
                      value={formData.file2path}
                      onChange={handleChange}
                      placeholder="Enter Target Path"
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="flex-auto">
                    <Button
                      label="Test Connection..."
                      type="test"
                      icon="pi pi-check"
                      severity="primary"
                      onClick={(e) => handleTestConnection(e, 2)}
                      className="border-round-lg w-full"
                    />
                  </div>
                </div>
                <Dialog
                  header="Connection Test"
                  visible={visible}
                  style={{ width: "30vw" }}
                  onHide={() => setVisible(false)}
                  footer={footerContent}
                >
                  <Card>
                    {" "}
                    {loading ? (
                      <div className="card flex justify-content-center">
                        <ProgressSpinner
                          style={{ width: "50px", height: "50px" }}
                          strokeWidth="5"
                          fill="var(--surface-ground)"
                          animationDuration=".5s"
                        />
                      </div>
                    ) : (
                      <>
                        <h3 className="m-0 py-3">Status: {responseStatus}</h3>
                        <p className="m-0">Message: {responseMessage}</p>
                      </>
                    )}
                  </Card>
                </Dialog>
              </div>
            </div>
            <div className="col-12">
              <Button
                label="Schedule"
                type="submit"
                icon="pi pi-calendar"
                severity="primary"
                onClick={handleClick}
                className="border-round-lg"
              />
            </div>
          </div>

          <div className="row m-auto"></div>
        </form>
      </div>
    </>
  );
}
